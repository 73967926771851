import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';
import { ApiService } from '../../../services/api.service';

@Component({
    selector: '.p-verify-account'
})
export class VerifyAccountComponent extends ComponentBase<HTMLElement> {

    @Input('email')
    public _email: string = null;

    @ChildRef('.p-verify-account__title3')
    public _title3Element: HTMLDivElement = null;

    public constructor(node: HTMLElement, 
        private _apiService: ApiService) {
        super(node);        
    }   
    
    @ClickListener('.p-verify-account__send') 
    public async onSend(): Promise<void> {
        const { email } = this.getFormData('.p-verify-account__step0');
        if (email) {
            this._email = email;
            this.onResend();
        }
    }

    @ClickListener('.p-verify-account__resend') 
    public async onResend(): Promise<void> {
        await this._apiService.post('/api/v1/user/resend-verification', { 
            body: {
                email: this._email
            }
        });
        this._title3Element.innerHTML = USER_LOCALE.resendVerificationMessage.format(`<span>${this._email}</span>`)
        this.addClass('p-verify-account--resent');
        this.showInfo(`${USER_LOCALE.verifyAccountComment2.format(this._email)} ${USER_LOCALE.verifyAccountComment3}`);
    }
}