import { Component } from '../../../../../lib/web/components/component';
import { PopupComponent } from '../../../../../lib/web/components/popup.component';
import { SessionService } from '../../../services/session.service';

@Component({
    selector: '.m-mobile-dropdown-menu'
})
export class MobileDropdownMenuComponent extends PopupComponent<HTMLInputElement> {

    public constructor(node: HTMLInputElement, private _sessionService: SessionService) {
        super(node);
    }

    public onInit(): void {
        super.onInit();
    }
}