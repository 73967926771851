import { Component } from '../../../../../lib/web/components/component';
import { ModalComponent as ModalComponentBase } from '../../../../../lib/web/components/modal.component';
import { LocalStorageService } from '../../../../../lib/web/services/local-storage.service';
import { ApiService } from '../../../services/api.service';
import { EjsService } from '../../../services/ejs.service';

interface Modal {
    id: number, 
    recurrence: 'once-per-device' | 'frequency', 
    frequency?: number, 
    maxTimes?: number, 
    isClosable?: boolean;
    primaryButtonUrl?: string;
    primaryButtonOpenInNewWindow?: boolean;
    secondaryButtonUrl?: string;
    secondaryButtonOpenInNewWindow?: boolean;
}

@Component({
    selector: '.o-modal'
})
export class ModalComponent extends ModalComponentBase<HTMLInputElement> {
    
    private _modals: Modal[] = null;
    private _modalsShowCount: { [id: string]: number } = {};
    private _pendingTriggers: ('login' | 'new-session')[] = [];

    public constructor(node: HTMLInputElement,
        private _apiService: ApiService,
        private _ejsService: EjsService,
        private _localStorageService: LocalStorageService) {
        super(node);

        this.isDisposable = false;

        this.addCustomWindowEventListener('page-loaded', () => {
            this.loadPending();
        });
        this.addCustomWindowEventListener('login', () => {
            setTimeout(() => {
                this._pendingTriggers.push('login');
                this.loadPending();    
            }, 1000);
        });
        this.addCustomWindowEventListener('new-session', () => {
            this._pendingTriggers.push('new-session');
            this.loadPending();
        });
    }

    public onInit(): void {
        super.onInit();        
    }

    private async loadPending(): Promise<void> {
        if (!this.isLoadingPage) {
            while (this._pendingTriggers?.length) {                                
                const [first] = this._pendingTriggers;
                this._pendingTriggers = this._pendingTriggers.slice(1);
                await this.loadData(first);
            }
        }
    }

    private async loadData(trigger: 'login' | 'new-session'): Promise<void> {
        this.isLoadingData = true;
        this._modals = await this._apiService.get(`/api/v1/modal/${trigger}`);
        if (this._modals?.length) {
            for (const m of this._modals) {
                this._modalsShowCount[m.id] = 0;
                const isOncePerDevice: boolean = m.recurrence === 'once-per-device';
                const isShowed: boolean | { time: Date, times: number } = this.handleIsShowed(m.id, isOncePerDevice, m.frequency, m.maxTimes);
                if (isShowed) {
                    return;
                }
                if (m.id?.toString().startsWith('pwd-') && location.pathname == '/u/profile/change-password') {
                    return;
                }
                const modalElement: HTMLDivElement = this.createModal(m);
                this.node.appendChild(modalElement);
                await this.open(modalElement);
                this._modalsShowCount[m.id]++;
                this._localStorageService.set(`modal-${m.id}`, isOncePerDevice ? true : { time: Date.now(), times: this._modalsShowCount[m.id] });
            }
        }
        this.isLoadingData = false;
    }

    private handleIsShowed(id: number, isOncePerDevice: boolean, frequency?: number, maxTimes?: number): boolean | { time: Date, times: number } {
        const modalShowed: { time: Date, times: number } = this._localStorageService.get(`modal-${id}`);
        if (isOncePerDevice) {
            return modalShowed;
        }
        else {
            const time: number = new Date().addHours(frequency).getTime();
            if (modalShowed) {
                const isTimeShowed: boolean = time > new Date(modalShowed.time).getTime();
                if (maxTimes) {
                    this._modalsShowCount[id] = modalShowed.times || 1;
                    return isTimeShowed && modalShowed.times <= maxTimes;
                }
                else {
                    return isTimeShowed;
                }
            }
        }
    }

    private onButtonClick(button: HTMLButtonElement, modalElement: Element, url?: string, newTab?: boolean) {
        button.onclick = () => {
            if (url) {
                window.open(url, newTab ? '_blank' : '_self').focus()
            }
            this.close(modalElement);
        };
    }

    private createModal(modal: Modal) {
        const element: HTMLDivElement = this.createElement('o-modal-item', this._ejsService.render('organisms/modal/modal-item', { modal }));
        const closeButton: HTMLButtonElement = element.querySelector('.u-modal-close-button');
        if (closeButton) {
            closeButton.onclick = () => this.close(element);
        }
        const primaryButton: HTMLButtonElement = element.querySelector('.u-button--primary');
        if (primaryButton) {
            this.onButtonClick(primaryButton, element, modal.primaryButtonUrl, modal.primaryButtonOpenInNewWindow);
        }
        const secondaryButton: HTMLButtonElement = element.querySelector('.u-button--secondary');
        if (secondaryButton) {
            this.onButtonClick(secondaryButton, element, modal.secondaryButtonUrl, modal.secondaryButtonOpenInNewWindow);
        }
        return element;
    }

    private createElement(className: string, value?: string) {
        const element = document.createElement('div');
        element.classList.add(className);
        if (value) {
            element.innerHTML = value;
        }
        return element;
    }
}