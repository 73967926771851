import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { EjsService } from '../../../services/ejs.service';

@Component({
    selector: '.o-search-suggestions'
})
export class SearchSuggestionsComponent extends ComponentBase<HTMLInputElement> {

    @ChildRef('.o-search-suggestions__content')
    private _contentElement: HTMLDivElement = null;

    public constructor(node: HTMLInputElement, private _ejsService: EjsService) {
        super(node);
    }
    
    public load(suggestions?: { id?: string, term: string, type: 'suggestion' | 'recent' }[]): void {
        this._contentElement.innerHTML = '';
        (suggestions || []).forEach(s => {
            const term: HTMLDivElement = document.createElement('div');
            term.classList.add('o-search-suggestions__term');
            term.innerText = s.term;
            
            const icon: HTMLDivElement = document.createElement('div');
            icon.classList.add('o-search-suggestions__icon');
            icon.innerHTML += this._ejsService.render('atoms/icon/icon', { key: s.type == 'suggestion' ? 'search': 'clock' });

            const remove: HTMLButtonElement = document.createElement('button');
            remove.classList.add('o-search-suggestions__remove', 'u-button');
            remove.addEventListener('click', e => {
                e.stopPropagation();
                this.dispatchCustomEvent('remove', { id: s.id })
            });
            remove.innerHTML += this._ejsService.render('atoms/icon/icon', { key: 'close' });

            const item: HTMLButtonElement = document.createElement('button');                    
            item.classList.add('o-search-suggestions__item', 'u-button')
            item.addEventListener('click', () => this.dispatchCustomEvent('selected', { term: s.term }));

            item.appendChild(icon);
            item.appendChild(term);
            if (s.type == 'recent') {
                item.appendChild(remove);
            }
            this._contentElement.appendChild(item);
        });
        this.addOrRemoveClass(this._contentElement.innerHTML != '', 'o-search-suggestions--show');
    }
}