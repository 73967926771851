import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';

@Component({
    selector: '.a-timer'
})
export class TimerComponent extends ComponentBase<HTMLInputElement> {
    
    private _interval: NodeJS.Timer = null;

    public constructor(node: HTMLInputElement) {
        super(node);
    }

    public onInit(mode?: 'load' | 'redirect'): void {       
        this._node.style.setProperty('--size', '200px');
        this._node.style.setProperty('--border-width', '8px');
    }

    public start(seconds: number): void {
        this.stop();           

        this._node.style.setProperty('--time', `5s`);

        this.setInnerHTML(seconds.toString(), '.a-timer__value');
        this._interval = setInterval(() => {
            const value: number = parseInt(this.getInnerHTML('.a-timer__value')) - 1;
            this.setInnerHTML(value.toString(), '.a-timer__value');             
            if (value == 0) {
                this.stop();
                this.dispatchCustomEvent('end');
            }
        }, 1000);
    }

    public stop(): void {
        if (this._interval != null) {
            clearInterval(this._interval);
        }
    }
}