import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';

@Component({
    selector: '.a-capsule-segment-item'
})
export class CapsuleSegmentItemComponent extends ComponentBase<HTMLDivElement> {
  
    public constructor(node: HTMLDivElement) {
        super(node);
    }
}