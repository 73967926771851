import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';
import { SessionService } from '../../../services/session.service';

@Component({
    selector: '.o-image-block'
})
export class ImageBlockComponent extends ComponentBase<HTMLElement> {

    @Input('withRegister')
    private _withRegister: boolean = null;

    public constructor(node: HTMLElement, private _sessionService: SessionService) {

        super(node);  
    }

    public onInit(): void {
        super.onInit();
        if (this._sessionService.isSigned && this._withRegister)  {
            this.addClass('u-hidden-important', '.o-image-block__register');
        }
    }
}
