import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ChildrenRef } from '../../../../../lib/web/components/children-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';

@Component({
    selector: '.a-tab'
})
export class TabComponent extends ComponentBase<HTMLElement> {

    @ChildrenRef(HTMLButtonElement, '.a-tab__item')
    private _tabItems: HTMLButtonElement[] = null;
    @ChildRef('ul')
    private _list: HTMLUListElement = null;
    @ChildRef('.a-tab__arrow--start')
    private _startArrowElement: HTMLButtonElement;
    @ChildRef('.a-tab__arrow--end')
    private _endArrowElement: HTMLButtonElement;

    public constructor(node: HTMLElement) {
        super(node);

        this.addWindowEventListener('resize', () => this.checkOveflow());
    }

    public onInit(mode?: 'load' | 'redirect'): void {
        super.onInit();
        this.checkOveflow();        
    }

    private checkOveflow(): void {
        if (!document.body.classList.contains('touch')) {
            if (this._list.scrollWidth <= this._list.clientWidth) {
                this.addClass('u-hidden', '.a-tab__arrow');
            }
            else {
                this._endArrowElement.disabled = (this._list.scrollLeft + this._list.offsetWidth + 1) >= this._list.scrollWidth;
                this._startArrowElement.disabled = !this._list.scrollLeft; 
                this.removeClass('u-hidden', '.a-tab__arrow');               
                // this.addOrRemoveClass((this._list.scrollLeft + this._list.offsetWidth + 1) >= this._list.scrollWidth, 'u-hidden', '.a-tab__arrow--end');
                // this.addOrRemoveClass(!this._list.scrollLeft, 'u-hidden', '.a-tab__arrow--start');                
            }            
        }
    }

    @ClickListener('.a-tab__item')
    public onTabSelected(e: MouseEvent): void {
        const tabItemElement: HTMLButtonElement = e.currentTarget as HTMLButtonElement;
        if (this.selectionMode == 'default') {
            this.setSelected(tabItemElement.dataset.id);
        }
        else {
            this.dispatchCustomEvent('selection-changed', { id: tabItemElement.dataset.id });
        }
    }   

    @ClickListener('.a-tab__arrow')
    public onSlide(e: MouseEvent): void {
        const arrowElement: HTMLButtonElement = e.currentTarget as HTMLButtonElement;
        this.slide(arrowElement.classList.contains('a-tab__arrow--start') ? 'start' : 'end');
    }

    private slide(direction: 'start' | 'end'): void {
        const itemsWidth: number = this._list.offsetWidth;
        const items: HTMLLIElement[] = Array.from(this._list.querySelectorAll('li'));
        const itemWidth: number = itemsWidth / items.length;
        const distanceToScroll: number = itemWidth * 1.5;
        if (direction === 'end') {
            this._list.scrollLeft += distanceToScroll;
        } 
        else if (direction === 'start') {
            this._list.scrollLeft -= distanceToScroll;
        }
        setTimeout(() => this.checkOveflow(), 500);
    }
       
    public setSelected(id: string): void {
        if (this._node.dataset.selected != id) {
            const item: any = this._tabItems.find(i => i.dataset.id == id);
            this._node.dataset.selected = item.dataset.id;
            this._tabItems.forEach(i => i.classList.remove('a-tab__item--selected'));
            this._tabItems.find(i => i.dataset.id.toString() == item.dataset.id.toString()).classList.add('a-tab__item--selected');
            if (this.selectionMode == 'default') {
                this.dispatchCustomEvent('selection-changed', { id: this._node.dataset.selected });
            }
        }
    }

    public get selectionMode(): 'default' | 'external' {
        return this._node.dataset.selectionMode as 'default' | 'external';
    }
}