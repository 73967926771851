import { Component } from '../../../../../lib/web/components/component';
import { EventListener } from '../../../../../lib/web/components/event-listener';
import { ModalComponent } from '../../../../../lib/web/components/modal.component';

@Component({
    selector: '.a-item-selector-modal'
})
export class ItemSelectorModalComponent extends ModalComponent<HTMLDivElement> {

    public constructor(node: HTMLInputElement) {
        super(node);
    }    

    public onInit(): void {
        super.onInit();
    }

    public async open(selected: any): Promise<any> {
        const inputs: HTMLInputElement[] = Array.from(this._node.querySelectorAll(`input[name='item-selector-option']`));
        inputs.forEach(i => i.checked = false);
        const input: HTMLInputElement = inputs.find(i => i.value == selected);        
        if (input) {
            input.checked = true;
        }
        return await super.open();
    }  

    @EventListener( 'change', '[name=item-selector-option]')
    public onChange(): void {
        const selected: any = (this.node.querySelector(`input[name='item-selector-option']:checked`) as HTMLInputElement || { value: null }).value;
        this.close(selected);
    }
}