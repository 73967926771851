import { Injectable } from "../../../lib/web/reflection/injectable";
import { HttpClientResponse, HttpService } from "../../../lib/web/services/http.service";
import { IApiService } from "./iapi.service";
import { Session, SessionService } from "./session.service";

@Injectable({ type: 'singleton', token: 'API_SERVICE_TOKEN'})
export class ApiService implements IApiService {

    public constructor(private _httpService: HttpService, private _sessionService: SessionService) {
    }

    public init(): void {
    }

    public async get(url: string, data?: { headers?: any, query?: any }): Promise<any> {
        const { headers } = data || {};
        const response: HttpClientResponse = await this._httpService.get(url, {
            ...data,
            headers: this.getApiHeaders(headers),
        });
        return this.getApiResponse(response);
    }

    public async post(url: string, data?: { headers?: any, query?: any, body?: any}): Promise<any> {
        return await this.nonQueryRequest('post', url, data);
    }

    public async put(url: string, data?: { headers?: any, query?: any, body?: any}): Promise<any> {
        return await this.nonQueryRequest('put', url, data);
    }

    public async delete(url: string, data?: { headers?: any, query?: any, body?: any}): Promise<any> {
        return await this.nonQueryRequest('delete', url, data);
    }

    public async nonQueryRequest(method: 'post' | 'put' | 'delete', url: string, data?: { headers?: any, query?: any, body?: any}): Promise<any> {
        try {
            const { headers, body } = data || {};
            const response: HttpClientResponse = await (this._httpService as any)[method](url, {
                ...data,
                headers: this.getApiHeaders(headers),
                body: this.getApiBody(body)        
            });
            return this.getApiResponse(response);
        }
        catch (e: any) {
            throw this.getApiResponse(e, true);
        }
    }   

    private getApiResponse(response: HttpClientResponse, isError?: boolean): any {
        const { statusCode, data, headers } = response;
        try {   
            if (isError) {
                if (statusCode == 401 && (headers || {})['x-authenticate'] == 'Env') {
                    location.assign(CONFIG.ENV_PROTECT_METHOD == 'code' ? '/e/code': '/e/sign-in');
                }
                else if (statusCode == 499) {
                    location.assign('/waiting-room');
                }
                else {
                    return {
                        ...response,
                        data: data ? JSON.parse(data): null
                    };
                }
            }
            else {
                return JSON.parse(data);
            }            
        }
        catch (e: any) {
            return response;
        }
    }

    private getApiBody(body: any): string {
        return body ? JSON.stringify(body): null;
    }

    private getApiHeaders(headers: any): any {
        const apiHeaders: any = {                
            ...(headers || {}),
            'Content-Type': 'application/json;charset=UTF-8',
        };        
        return apiHeaders;
    }    

    public async checkSession(): Promise<void> {
        if (this._sessionService.isSigned && !this._sessionService.sessionChecked) {
            const user: any = await this.get('/api/v1/user/me');
            this._sessionService.renewed(user);
            window.dispatchEvent(new CustomEvent('user-picture-changed'));
        }
        else {
            this._sessionService.checked();
        }

        if (this._sessionService.isNewSession) {
            this._sessionService.setNewSession(false);
        }
    }
}