import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { DataEventService } from '../../../services/data-event.service';
import { SessionService } from '../../../services/session.service';

@Component({
    selector: '.o-footer'
})
export class FooterComponent extends ComponentBase<HTMLElement> {
      
    public constructor(node: HTMLElement, private _dataEventService: DataEventService, private _sessionService: SessionService) {
        super(node);
    }

    public onInit(mode?: 'load' | 'redirect'): void {
        super.onInit(mode);
        const pageTypeElement: HTMLElement = document.querySelector('[data-page-type]');
        if (pageTypeElement) {
            this._dataEventService.page(this._sessionService.get()?.user?.id, pageTypeElement.dataset.pageType);
        }
    }

    @ClickListener('#show-cookies')
    public onShowCookies(): void {
        OneTrust.ToggleInfoDisplay();
    }    
}