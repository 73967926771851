import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { KeyDownListener } from '../../../../../lib/web/components/key-down-listener';
import { Key } from '../../../../../lib/web/core/key';
import { ApiService } from '../../../services/api.service';
import { ScrollService } from '../../../../../lib/web/services/scroll.service';

@Component({
    selector: '.p-env-sign-in'
})
export class EnvSignInComponent extends ComponentBase<HTMLElement> {

    public constructor(node: HTMLElement, private _apiService: ApiService, private _scrollService: ScrollService) {
        super(node);        
    }    

    public onInit(): void {
        this.focus('#txtEmail');
    }

    @KeyDownListener(Key.Enter, '#txtEmail')
    public onEmailEnter(): void {
        this.signIn();
    }

    @KeyDownListener(Key.Enter, '#txtPassword')
    public onPasswordEnter(): void {
        this.signIn();
    }
   
    @ClickListener('.p-env-sign-in__send')
    public onSend(): void {
        this.signIn();
    }  

    private async signIn(): Promise<void> {
        const { username, password } = this.getFormData('.p-env-sign-in__form');
        try {
            if (username && password) {
                await this._apiService.post('/api/v1/env/sign-in', { 
                    body: {
                        username,
                        password
                    }
                });                
                this.redirect('/');
            }
        }
        catch (e: any) {
            if (e == 401 || e.statusCode == 401) {
                this.setErrors('.p-env-sign-in__errors', USER_LOCALE.envSignInFailed);
            }
            else {
                this.showUnexpectedError(e);
            }
            this._scrollService.scrollToTop();
        }
    }
}