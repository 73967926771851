import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ModalComponent } from '../../../../../lib/web/components/modal.component';
import { ApiService } from '../../../services/api.service';
import { ToggleSelectorComponent } from '../../atoms/toggle-selector/toggle-selector.component';

@Component({
    selector: '.m-manage-disciplines'
})
export class ManageDisciplinesComponent extends ModalComponent<HTMLInputElement> {

    @ChildRef()
    private _disciplineSelector: ToggleSelectorComponent = null;
    
    public constructor(node: HTMLInputElement, private _apiService: ApiService) {
        super(node);
    }    

    public onInit(): void {
        super.onInit();
        this._disciplineSelector.addCustomEventListener('selection-changed', () => this.enable('.m-manage-disciplines__save'));
    }

    public async open(): Promise<boolean> {
        this.disable('.m-manage-disciplines__save');
        this._disciplineSelector.reset();
        return super.open();
    }

    @ClickListener('.m-manage-disciplines__cancel')
    public onCancel(): void {
        this.close();
    }

    @ClickListener('.m-manage-disciplines__save')
    public async onSave(): Promise<void> {
        const selected: number[] = this._disciplineSelector.selected;
        if (selected && selected.length) {
            await this._apiService.post('/api/v1/user/disciplines', { 
                body: {
                disciplines: selected
                }
            });                
        }
        this.close(true);
    }
}