import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { EventListener } from '../../../../../lib/web/components/event-listener';
import { Input } from '../../../../../lib/web/components/input';
import { ItemSelectorComponent } from '../../atoms/item-selector/item-selector.component';
import { TabComponent } from '../../atoms/tab/tab.component';

@Component({
    selector: '.o-season-selector'
})
export class SeasonSelectorComponent extends ComponentBase<HTMLElement> {

    @ChildRef()
    private _tab: TabComponent = null;
    @ChildRef()
    private _itemSelector: ItemSelectorComponent = null;
    
    public constructor(node: HTMLElement) {
        super(node);
    }

    @EventListener('selection-changed', '.a-tab')
    public onTabSelectionChanged(e: CustomEvent): void {
        // this.setSelected(e.detail.id);
        this.dispatchCustomEvent('selection-changed', { id: e.detail.id  });
    }

    @EventListener('selection-changed', '.a-item-selector')
    public onItemSelectorSelectionChanged(e: CustomEvent): void {
        // this.setSelected(e.detail.id);
        this.dispatchCustomEvent('selection-changed', { id: e.detail.id  });
    }
    
    public setSelected(id: string): void {        
        if (this._node.dataset.selected != id) {
            this._node.dataset.selected  = id;
            this._tab.setSelected(this._node.dataset.selected );
            this._itemSelector.setSelected(this._node.dataset.selected );
            // this.dispatchCustomEvent('selection-changed', { id: this._node.dataset.selected  });
        }
    }
}