import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { PopupComponent } from '../../../../../lib/web/components/popup.component';
import { DataEventService } from '../../../services/data-event.service';

@Component({
    selector: '.m-dropdown-menu'
})
export class DropdownMenuComponent extends PopupComponent<HTMLInputElement> {

    public constructor(node: HTMLInputElement, private _dataEventService: DataEventService) {
        super(node);
    }

    @ClickListener('.m-dropdown-menu__item a')
    public onItemClicked(e: MouseEvent): void {
        const item = e.currentTarget as HTMLAnchorElement;
        this.sendClickMenuEvent(item);
        this.close();
    }

    public async open(element?: HTMLElement, e?: MouseEvent): Promise<void> {       
        this._node.style.height = '0';
        const promise: Promise<void> = super.open(null, e);
        
        const { width } = this._node.getBoundingClientRect();
        const { left: parentLeft, width: parentWidth } = element.getBoundingClientRect();
        const header: HTMLDivElement = document.querySelector('.o-header');
        const { bottom } = header.getBoundingClientRect();
        this._node.style.left = `${parentLeft + (parentWidth / 2) - (width / 2)}px`;
        this._node.style.top = `${bottom}px`;        
        
        this._node.style.height = null;
        return promise;
    }

    private sendClickMenuEvent(item: HTMLAnchorElement) {
        const { href, innerText } = item;
        this._dataEventService.clickMenu(href, innerText, innerText, 'desktop-menu', document.title, location.href);
    }
}