import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';

@Component({
    selector: '.a-toggle-selector'
})
export class ToggleSelectorComponent extends ComponentBase<HTMLElement> {

    public constructor(node: HTMLElement) {
        super(node);
    }

    public reset(): void {
        this.removeClass('u-button--primary', '.a-toggle-selector__item');
        this.addClass('u-button--secondary', '.a-toggle-selector__item');
        this.dispatchCustomEvent('selection-changed');
    }

    @ClickListener('.a-toggle-selector__item')
    public onItemClicked(e: MouseEvent): void {
        const item: HTMLButtonElement = e.target as HTMLButtonElement;
        this.toggleItem(item);
    }

    private toggleItem(item: HTMLButtonElement): void {
        if (item) {
            this.toggleClasses(item.classList.contains('u-button--secondary'), 'u-button--primary', 'u-button--secondary', item);
            this.dispatchCustomEvent('selection-changed');
        }
    }

    public get selected(): number[] {
        return Array.from(this.node.querySelectorAll('.a-toggle-selector__item.u-button--primary')).map(n => parseInt((n as HTMLElement).dataset.id));
    }

    public set selected(value: number[]) {
        this.reset();
        (value || []).forEach(v => this.toggleItem(this._node.querySelector(`.a-toggle-selector__item[data-id='${v}']`)));
    }
}