import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ModalComponent } from '../../../../../lib/web/components/modal.component';

@Component({
    selector: '.o-error-modal'
})
export class ErrorModalComponent extends ModalComponent<HTMLInputElement> {

    public constructor(node: HTMLInputElement) {
        super(node);
    }    

    public onInit(): void {
        super.onInit();
    }    

    public async open(error: any): Promise<any> {        
        this.setInnerHTML(this.getErrorContent(error), '.o-error-modal__content');
        return super.open(error);
    }

    @ClickListener('.o-error-modal__close')
    public onClose(): void {
        this.close();
    }

    private getErrorContent(error: any): string {
        return Object.keys(error).map(k => {
            const value: any = error[k];
            if (typeof value == 'object') {
                return JSON.stringify(value);
            }
            else {
                return value;
            }
        }).join('</br>');
    }
}