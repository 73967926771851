import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { EventListener } from '../../../../../lib/web/components/event-listener';
import { QueryString } from '../../../../../lib/web/core/query-string';
import { ToastService } from '../../../../../lib/web/services/toast.service';
import { ApiService } from '../../../services/api.service';
import { SessionService } from '../../../services/session.service';
import { ValidationService } from '../../../services/validation.service';
import { ProfileDataComponent } from '../../molecules/profile-data/profile-data.component';

@Component({
    selector: '.o-profile'
})
export class ProfileComponent extends ComponentBase<HTMLElement> {

    @ChildRef()
    private _profileData: ProfileDataComponent = null;
    @ChildRef('#profile-image')
    private _fileElement: HTMLInputElement = null;
    @ChildRef('.o-profile__image')
    private _pictureElement: HTMLImageElement = null;

    public constructor(node: HTMLElement, 
        private _toastService: ToastService, 
        private _apiService: ApiService, 
        private _sessionService: SessionService,
        private _validationService: ValidationService) {
        super(node);
    }

    public onInit(): void {
        this._toastService.hide('pendingProfileData');
        this._profileData.addCustomEventListener('profile-updated', (data: { fullname: string }) => {
            this.setInnerHTML(data.fullname, '.o-profile__user-name');
        });
        if (this._pictureElement.src) {
            this.removeClass('u-hidden-important', '.o-profile__image');
            this.removeClass('u-hidden-important', '.o-profile__remove-picture');
        }
        else {
            this.removeClass('u-hidden-important', '.o-profile__signed-icon');
        }
    }

    @ClickListener('.o-profile__remove-picture')
    public onRemovePicture() {
        this.removePicture();
    }

    @EventListener('change', '#profile-image')
    public onPictureChange(): void {
        this.loadPicture();
    }

    private loadPicture(): void {
        const file: File = this._fileElement.files[0];
        if (file) {
            const reader: FileReader = new FileReader();
            reader.onload = () => {            
                const fileExtension: string = file.name.split('.').reverse()[0];
                let fileContent: string = (reader.result as string).replace(/^data:(.*;base64,)?/, '');
                if (fileContent.length % 4 > 0) {
                    fileContent += '='.repeat(4 - (fileContent.length % 4));
                }
                this._fileElement.value = '';
                this.savePicture(fileContent, fileExtension);
            };
            reader.onerror = (error) => {
                logger.error(error);
                this.showUnexpectedError(error);
            };
            reader.readAsDataURL(file);
        }
    }

    private async savePicture(content: string, extension: string): Promise<void> {        
        const errors: string[] = this._validationService.validateAvatar(content);
        if (!(errors || []).length) {
            try {
                this.removeClass('u-hidden-important', '.o-profile__picture-loader');    
                const { url } = await this._apiService.post('/api/v1/user/picture', { 
                    body: {
                        content,
                        extension
                    }
                });                
                this._pictureElement.src = QueryString.addParamToUrl(`${CONFIG.STORAGE_URL}/${url}`, 'timestamp', Date.now().toString());        
                this.addClass('u-hidden-important', '.o-profile__signed-icon');
                this.removeClass('u-hidden-important', '.o-profile__image');
                this.removeClass('u-hidden-important', '.o-profile__remove-picture');        
                this.addClass('u-hidden-important', '.o-profile__picture-loader');
                this._sessionService.setPictureUrl(url);
            }
            catch (e: any) {
                this.addClass('u-hidden-important', '.o-profile__picture-loader');
                if (e.statusCode == 400) {
                    this.showInfo(USER_LOCALE.error, e.data?.message);
                }
                else {
                    this.showUnexpectedError(e);
                }
            }
        }
        else {
            this.showInfo(USER_LOCALE.error, errors.join('\n'));
        }
    }

    private async removePicture(): Promise<void> {        
        try {
            this.removeClass('u-hidden-important', '.o-profile__picture-loader');    
            await this._apiService.delete('/api/v1/user/picture');                
            this._pictureElement.src = '';
            this.removeClass('u-hidden-important', '.o-profile__signed-icon');
            this.addClass('u-hidden-important', '.o-profile__image');    
            this.addClass('u-hidden-important', '.o-profile__remove-picture');    
            this.addClass('u-hidden-important', '.o-profile__picture-loader');
            this._sessionService.setPictureUrl(null);
            this._fileElement.value = '';
        }
        catch (e: any) {
            this.addClass('u-hidden-important', '.o-profile__picture-loader');
            this.showUnexpectedError(e);
        }
    }
}