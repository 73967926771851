import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { EventListener } from '../../../../../lib/web/components/event-listener';

@Component({
    selector: '.p-layout'
})
export class LayoutComponent extends ComponentBase<HTMLElement> {

    public constructor(node: HTMLElement) {
        super(node);       
    }

    @EventListener('row-data-loaded', '.o-row')
    public onRowDataLoaded(e: CustomEvent): void {  
        const node: HTMLElement = (e.currentTarget as HTMLElement).closest('.p-layout__row');
        node.classList.add(`p-layout__row--${e.detail.format}`);
    }
}