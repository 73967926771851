import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';
import { Agent } from '../../../../../lib/web/core/agent';
import { CookieService } from '../../../../../lib/web/services/cookie.service';

@Component({
    selector: '.m-app-download'
})
export class AppDownloadComponent extends ComponentBase<HTMLAnchorElement> {

    @Input('urlAndroid')
    private _urlAndroid: string = null;
    @Input('urlApple')
    private _urlApple: string = null;

    @ChildRef('.m-app-download__download')
    private _downloadElement: HTMLAnchorElement = null;
    
    public constructor(node: HTMLAnchorElement, private _cookieService: CookieService) {
        super(node);
    }

    public onInit(mode?: 'load' | 'redirect'): void {
        super.onInit(mode);
        setTimeout(() => this.check());
    }

    @ClickListener('.m-app-download__close')
    public onClose(e: MouseEvent): void {
        e.preventDefault();
        this.close();
    }

    @ClickListener('.m-app-download__download')
    public onDownload(e: MouseEvent): void {
        this.close();
    }

    @ClickListener()
    public onClick(): void {
        // this.close();
    }   

    private check(): void {
        if (!this._cookieService.get('app-download')) {
            let deviceType: 'android' | 'ios' = null;
            const userAgent: string = window.navigator.userAgent.toLowerCase();
            const vendor: string = (window.navigator.vendor || '').toLowerCase();
            if (Agent.android()) {
                deviceType = 'android';
            } 
            else if (Agent.iOS()) {
                // Exclude safari browser
                if (!Agent.safari()) {
                    deviceType = 'ios';
                }
            }
            if (location.pathname == '/u/profile/change-password') {
                deviceType = null;
            }
            if (deviceType) {
                this.addClass(`m-app-download--${deviceType}`);
                this._downloadElement.href = deviceType == 'android' ? this._urlAndroid: this._urlApple;
                setTimeout(() => {
                    this.show();
                }, 500);
            }
            else {
                this.remove();
            }
        }
        else {
            this.remove();
        }
    }

    private show(): void {
        this.removeClass('u-hidden-important');
        setTimeout(() => {
          this.addClass('m-app-download--visible');
        }, 500);
    }

    private close(): void {
        this.removeClass('m-app-download--visible');
        this._cookieService.set('app-download', '1', { expires: new Date().addHours(24) });
        setTimeout(() => {
            this.addClass('u-hidden-important');
            this.remove();
        }, 1000);        
    }

    private remove(): void {
        if (!this.isDisposed) {
            this._node.parentNode.removeChild(this._node);
            this.dispose();
        }
    }
}