import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { EventListener } from '../../../../../lib/web/components/event-listener';

@Component({
    selector: '.a-text-box'
})
export class TextBoxComponent extends ComponentBase<HTMLInputElement> {

    @ChildRef('input')
    private _inputElement: HTMLInputElement = null;

    public constructor(node: HTMLInputElement) {
        super(node);
    }

    public onInit(): void {
        (this._node as any).name = this._inputElement.name;
    }

    @ClickListener('.a-text-box__clean')
    public onClean(): void {
        const cleanedValue: string = this.value;
        this.value = null;
        this.focus();
        this.dispatchCustomEvent('cleaned', { value: cleanedValue });
    }
    
    @EventListener('blur', 'input')
    public onBlur(): void {
        this.dispatchCustomEvent('blur');
    }

    @EventListener('input', 'input')
    public onInput(): void {
        this.addOrRemoveClass(this.value && !this._inputElement.disabled, 'a-text-box--not-empty');
        this.dispatchCustomEvent('input');
    }

    public focus(): void {
        this._inputElement.focus();
    }

    public get value(): string {
        return this._inputElement.value;
    }

    public set value(value: string) {
        this._inputElement.value = value;
        this.onInput();
    }

    public get name(): string {
        return this.node.name;
    }
}