import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';

@Component({
    selector: '.o-featured'
})
export class FeaturedComponent extends ComponentBase<HTMLElement> {

    public constructor(node: HTMLElement) {
        super(node);
    }

    public onInit(): void {
    }
}