import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';

@Component({
    selector: '.p-block-page'
})
export class BlockPageComponent extends ComponentBase<HTMLElement> {

    public constructor(node: HTMLElement) {
        super(node);             
    }

    public onInit(): void {
        super.onInit();
    }   
}