import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ModalComponent } from '../../../../../lib/web/components/modal.component';
import { ApiService } from '../../../services/api.service';

@Component({
    selector: '.m-share'
})
export class ShareComponent extends ModalComponent<HTMLInputElement> {

    private _data: { title: string, description: string, url: string } = null;

    public constructor(node: HTMLInputElement, private _apiService: ApiService) {
        super(node);

        this.isDisposable = false;
    }    

    public async open(data: { title: string, description: string, url: string }): Promise<string> {
        this._data = data;
        this.setInnerHTML(data.title, '.m-share__shared-title');
        return await super.open();
    }

    @ClickListener('.m-share__app')
    public onAppClicked(e: MouseEvent): void {
        const appElement: HTMLButtonElement = e.currentTarget as HTMLButtonElement;
        const { title, description, url } = this._data || {};
        let targetUrl: string = null;
        let result: string = null;
        if (appElement.classList.contains('m-share__app--facebook')) {
            targetUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
            result = 'facebook';
        }
        else if (appElement.classList.contains('m-share__app--twitter')) {
            targetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`;
            result = 'twitter';
        }
        else if (appElement.classList.contains('m-share__app--whatsapp')) {
            targetUrl = `https://wa.me/?text=${encodeURIComponent(`${title} ${url}`)}`;
            result = 'whatsapp';
        }
        else {
            navigator.clipboard.writeText(url);
            result = 'clipboard';
        }
        if (targetUrl) {
            window.open(targetUrl, '_blank');
        }
        this.close(result);
    }
}