import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';

@Component({
    selector: '.a-countdown-button'
})
export class CountdownButtonComponent extends ComponentBase<HTMLInputElement> {
        
    private _interval: NodeJS.Timer = null;

    @Input('seconds')
    private _seconds: number = null;

    public constructor(node: HTMLInputElement) {
        super(node);
    }

    public onInit(mode?: 'load' | 'redirect'): void {  
    }

    public start(): void {
        this.stop();           

        let value: number = this._seconds;
        let counter: number = this._seconds;
        this._interval = setInterval(() => {
            value = value - 0.01;
            if (value <= (counter - 1)) {
                counter--;
                this.setInnerHTML(counter.toString(), '.a-countdown-button__counter');
            }
            this._node.style.setProperty('--value', `${(100 / this._seconds) * (this._seconds - value)}%`);
            if (value <= 0) {
                this.stop();
                this.dispatchCustomEvent('end');
            }
        }, 10);        
    }

    public stop(): void {
        this.setInnerHTML(this._seconds.toString(), '.a-countdown-button__counter');
        this._node.style.setProperty('--value', `0%`);     
        if (this._interval != null) {
            clearInterval(this._interval);
        }       
    }
}