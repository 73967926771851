import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ChildrenRef } from '../../../../../lib/web/components/children-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';
import { DateTime } from '../../../../../lib/web/core/date-time';
import { Dom } from '../../../../../lib/web/core/dom';
import { Str } from '../../../../../lib/web/core/str';
import { ApiService } from '../../../services/api.service';
import { DataEventService } from '../../../services/data-event.service';
import { MediaOptions, MediaPlayerService, MediaType } from '../../../services/media-player.service';
import { SessionService } from '../../../services/session.service';
import { UserWatchingHistoryService } from '../../../services/user-watching-history.service';
import { IconComponent } from '../../atoms/icon/icon.component';
import { GridComponent } from '../../organisms/grid/grid.component';
import { SeasonSelectorComponent } from '../../organisms/season-selector/season-selector.component';

@Component({
    selector: '.p-collection'
})
export class CollectionComponent extends ComponentBase<HTMLElement> {

    private _userData: { isFavorite?: boolean } = null;
    private _playerBack: boolean = false;
    private _listsObserver: IntersectionObserver = null;

    @Input('id')
    private _collectionId: number = null;
    @Input('mediaType')
    private _collectionMediaType: number = null;
    @Input('slug')
    private _collectionSlug: string = null;
    @Input('firstChapterUrl')
    private _firstChapterUrl: string = null;
    @Input('firstChapterId')
    private _firstChapterId: number = null;
    @Input('image')
    private _image: string = null;    
    @Input('isComingSoon')
    private _isComingSoon: boolean = null;
    @Input('licenseStart')
    private _licenseStart: Date = null;
    @Input('licenseEnd')
    private _licenseEnd: Date = null;

    @ChildRef('.p-collection__synopsis-content')
    private _synposisContentElement: HTMLDivElement = null;
    @ChildRef('.p-collection__synopsis-more button')
    private _synposisMoreElement: HTMLButtonElement = null;
    @ChildrenRef(HTMLDivElement, '.p-collection__list')
    private _lists: HTMLDivElement[] = null;
    @ChildRef()
    private _seasonSelector: SeasonSelectorComponent = null;
    @ChildRef('.a-icon--heart')
    private _favoriteIcon: IconComponent = null;
    @ChildrenRef(GridComponent, '.o-grid')
    private _grids: GridComponent[] = null;
    @ChildRef('.p-collection__licenseStart')
    private _licenseStartElement: HTMLDivElement = null;
    @ChildRef('.p-collection__licenseEnd')
    private _licenseEndElement: HTMLDivElement = null;

    
    public constructor(node: HTMLElement, 
        private _apiService: ApiService,
        private _sessionService: SessionService,
        private _userWatchingHistoryService: UserWatchingHistoryService,
        private _mediaPlayerService: MediaPlayerService,
        private _dataEventService: DataEventService) {
        super(node);   
        
        this.addWindowEventListener('resize', () => this.checkSynopsis());

        this.addCustomWindowEventListener('pop-collection-player', e => {
            if (location.pathname.endsWith('/play-trailer')) {
                this.loadPlayer(false, 'trailer');
            }
            else if (!this._mediaPlayerService.isMiniPlayerActive) {
                this._mediaPlayerService.closePlayer();
            }
            e.isHandled = true;
        });
        this._mediaPlayerService.on('player-closed', async () => {
            this.enable('.p-collection__start-watch');
            this.enable('.p-collection__trailer');
            this.enable('.p-collection__unsigned-trailer');
            if (location.pathname.endsWith('/play-trailer')) {
                history.back();
            }
        }, this);
        this._mediaPlayerService.on('mini-player-activated', async () => {
            this.disable('.p-collection__start-watch');
            this.disable('.p-collection__trailer');
            this.disable('.p-collection__unsigned-trailer');
            if (this._playerBack) {
                history.back();
            }
            else if (location.pathname.endsWith('/play-trailer')) {
                this.replaceState(`${location.pathname.replace('/play-trailer', '')}${location.search}`, { popHandlerEvent: null });
            }
        }, this);
        this._mediaPlayerService.on('mini-player-deactivated', async () => {
            this.enable('.p-collection__start-watch');
            this.enable('.p-collection__trailer');
            this.enable('.p-collection__unsigned-trailer');
            if (this._mediaPlayerService.currentMedia) {
                this.setPlayState(true);
            }
        }, this);
    }

    public onInit(mode: 'load' | 'redirect'): void {
        super.onInit();
        this._playerBack = mode == 'redirect';
        if (this._sessionService.isSigned) {
            this.loadUserData();
        }
        if (this._seasonSelector) {
            this._seasonSelector.addCustomEventListener('selection-changed', (e: { id: number }) => this.setSeason(e.id, true));        
            this._listsObserver = new IntersectionObserver(() => this.checkSelectedSeason(), {});
            (this._lists || []).forEach(l => this._listsObserver.observe(l));
            if (location.pathname.split('/').length > 3) {
                const listSlug: string = location.pathname.split('/')[3];
                const selectedList: HTMLDivElement = this._node.querySelector(`.p-collection__list[data-slug="${listSlug}"]`);
                if (selectedList) {
                    setTimeout(() => {
                        this.setSeason(parseInt(selectedList.dataset.id));
                    }, 500);                
                }
            }    
        }
        this.addClass(this._sessionService.isSigned ? 'p-collection__watch--signed': 'p-collection__watch--unsigned', '.p-collection__watch');
        this.checkSynopsis();
        this.checkUserWatchingHistory();
        if (location.pathname.endsWith('/play-trailer')) {
            this.playTrailer(false);
            this._playerBack = false;
        }
        this.setLicenseDates();
        this.checkPlayingTrailer();
    }

    private setLicenseDates(): void {
        if (this._isComingSoon && this._licenseStart) {
            const hasTime: boolean = DateTime.licenseDateHasTime(this._licenseStart);
            this._licenseStartElement.innerHTML = (hasTime ? USER_LOCALE.availableFromWithTime: USER_LOCALE.availableFrom).format(DateTime.format(this._licenseStart, 'date'), DateTime.format(this._licenseStart, 'short-time'));
            this._licenseStartElement.classList.remove('u-hidden');
        }
        else if (this._licenseEnd) {
            const hasTime: boolean = DateTime.licenseDateHasTime(this._licenseEnd);
            this._licenseEndElement.innerHTML = (hasTime ? USER_LOCALE.availableUntilWithTime: USER_LOCALE.availableUntil).format(DateTime.format(this._licenseEnd, 'date'), DateTime.format(this._licenseEnd, 'short-time'));
            this._licenseEndElement.classList.remove('u-hidden');
        }
    }

    private checkSynopsis(): void {
        if (!this._synposisContentElement.classList.contains('p-collection__synopsis-content--full')) {
            this.addOrRemoveClass(this._synposisContentElement.scrollHeight <= this._synposisContentElement.clientHeight, 'u-hidden', '.p-collection__synopsis-more');
        }
    }

    private async loadUserData(): Promise<void> {
        const response: { isFavorite?: boolean } = await this._apiService.get(`/api/v1/user/data/${this._collectionId}/collection`);
        this._userData = response;
        this.setFavoriteOption();    
    }

    @ClickListener('.p-collection__synopsis-more button')
    public onMoreSynopsis(): void {
        if (this._synposisContentElement.classList.contains('p-collection__synopsis-content--full')) {
            this._synposisContentElement.classList.remove('p-collection__synopsis-content--full');
            this._synposisMoreElement.textContent = USER_LOCALE.continueReading;
        }
        else {
            this._synposisContentElement.classList.add('p-collection__synopsis-content--full');
            this._synposisMoreElement.textContent = USER_LOCALE.hide;
        }
    }

    @ClickListener('.p-collection__toggle-favorite')
    public onToogleFavorite(): void {
        this.setFavorite();        
    }

    @ClickListener('.p-collection__remove-continue-watching')
    public onRemoveContinueWatching(): void {
        this.removeContinueWatching();
    }

    @ClickListener('.p-collection__trailer')
    public async trailer(): Promise<void> {
        this.loadPlayer(true, 'trailer');
    }

    @ClickListener('.p-collection__unsigned-trailer')
    public unsignedTrailer(): void {
        this.loadPlayer(true, 'trailer');
    }  

    @ClickListener('.p-collection__start-watch')
    public async startWatch(): Promise<void> {
        this.playChapter();
    }
    
    private async removeContinueWatching(): Promise<void> {
        await this._apiService.post(`/api/v1/user/watching/archive/collection/${this._collectionId}`);
        this._userWatchingHistoryService.archive(this._collectionId, 'collection');
        this.addClass('u-hidden-important', '.p-collection__remove-continue-watching');
    }

    private checkUserWatchingHistory(): void {
        if (this._isComingSoon) {
            this.setInnerHTML(USER_LOCALE.comingSoonTag, '.p-collection__start-watch span');
            this.disable('.p-collection__start-watch');
        }
        else if (this._userWatchingHistoryService.isWatching(this._collectionId, 'collection')) {
            this.setInnerHTML(this._collectionMediaType == CONFIG.CAPSULE_VIDEO_TYPE ? USER_LOCALE.continueWatching: USER_LOCALE.continueListening, '.p-collection__start-watch span');            
            this.removeClass('u-hidden-important', '.p-collection__remove-continue-watching');
        }
        else {
            this.setInnerHTML(this._collectionMediaType == CONFIG.CAPSULE_VIDEO_TYPE ? USER_LOCALE.startWatch: USER_LOCALE.startListen, '.p-collection__start-watch span');
        }
        (this._grids || []).forEach(grid => {
            (grid.itemElements || []).forEach(item => {
                const id: number = parseInt(item.dataset.id);
                const watchingHistory: any = this._userWatchingHistoryService.getItem(id, 'capsule');
                if (watchingHistory) {
                    grid.setProgress(id, watchingHistory);
                }
            });
        });
    }

    private async setFavorite(): Promise<void> {
        const { isFavorite } = this._userData;
        if (isFavorite) {
            if (await this.showQuestion(USER_LOCALE.removeFromFavorite, USER_LOCALE.removeFromFavoriteQuestion.format('<br><br>'), 'ok',{ result: 'cancel' }, { result: 'ok' }) == 'ok') {
                await this._apiService.delete(`/api/v1/user/favorite/${this._collectionId}/collection`);
                this._userData.isFavorite = false;
                this.setFavoriteOption();
            }
        }
        else {            
            await this._apiService.post(`/api/v1/user/favorite/${this._collectionId}/collection`);
            this._userData.isFavorite = true;
            this.setFavoriteOption();
            this._dataEventService.addFavorite('collection', this._node.dataset.id, this._node.dataset.display, this._node.dataset.disciplines, this._node.dataset.typology);
        }
    }

    private setFavoriteOption(): void {
        const { isFavorite } = this._userData || {};
        this.removeClass('u-hidden-important', '.p-collection__toggle-favorite');
        this.setInnerHTML(isFavorite ? USER_LOCALE.removeFromFavorite: USER_LOCALE.addToFavorite, '.p-collection__toggle-favorite span:last-child');
        this._favoriteIcon.key = isFavorite ? 'heart-filled': 'heart';
    }

    private checkSelectedSeason(): void {
        const selectedList: HTMLDivElement = this._lists.filter(l => Dom.isScrolledIntoView(l)).last();
        if (selectedList) {
            this._seasonSelector.setSelected(selectedList.dataset.id);
        }
    }

    private setSeason(id: number, fromSelector: boolean = false): void {
        const selectedList: HTMLDivElement = this._lists.find(l => parseInt(l.dataset.id) == id);
        selectedList.scrollIntoView({ behavior: 'smooth' });
    }    

    private async playChapter(): Promise<void> {
        let chapterUrl: string = this._firstChapterUrl;
        let capsuleId: number = this._firstChapterId;
        if (this._userWatchingHistoryService.isWatching(this._collectionId, 'collection')) {
            capsuleId = this._userWatchingHistoryService.getItem(this._collectionId, 'collection').capsuleId;
            const { isArchived } = this._userWatchingHistoryService.getItem(capsuleId, 'capsule');
            chapterUrl = await this._apiService.get(`/api/v1/collection/continue-url/${this._collectionId}/${capsuleId}/${isArchived}`);            
        }
        // this.redirect(`${chapterUrl || this._firstChapterUrl}?play=true`);
        this.loadPlayer(false, 'capsule', {
            capsuleId,
            url: chapterUrl
        });
    }

    private playTrailer(pushState: boolean = true): void {        
        if (!this.checkPlayingTrailer()) {
            this.loadPlayer(pushState, 'trailer');
        }
    }  
    
    private checkPlayingTrailer(): boolean {
        const options: MediaOptions = this._mediaPlayerService.currentMedia || {} as any;    
        if (options.collectionId == this._collectionId && options.type == 'trailer') {
            this.disable('.p-collection__start-watch');
            this.disable('.p-collection__trailer');
            this.disable('.p-collection__unsigned-trailer');
            this.setPlayState(!location.pathname.endsWith('/play-trailer'));
            return true;
        }
        return false;
    }  

    private async loadPlayer(pushState: boolean = true, type: MediaType = 'capsule', chapter?: any): Promise<void> {                
        this._mediaPlayerService.loadMedia({
            capsuleId: type == 'capsule' ? chapter.capsuleId: null,
            collectionId: this._collectionId,
            type,
            image: this._image,
            owner: type == 'capsule' 
                ? {  pathname: chapter.url,
                    target: chapter.url
                }
                : {
                    pathname: location.pathname,
                    target: Str.getRelativeUrl(location.href)
                }
        });
        if (type == 'trailer') {
            this.setPlayState(pushState);
        }
    }

    private setPlayState(pushState: boolean = true): void {
        if (!this._mediaPlayerService.isMiniPlayerActive) {
            this.replaceState(`${location.pathname}${location.search}`, { popHandlerEvent: 'pop-collection-player' });
            if (pushState) {
                this.pushState(`${location.pathname}/play-trailer`, { popHandlerEvent: 'pop-collection-player' });
            }
        }
    }

    public dispose(): void {
        if (this._listsObserver) {
            this._listsObserver.disconnect();
            this._listsObserver = null;
        }
        this._mediaPlayerService.off(this);
        super.dispose();
    }
}