import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { EventListener } from '../../../../../lib/web/components/event-listener';

@Component({
    selector: '.a-date-box'
})
export class DateBoxComponent extends ComponentBase<HTMLInputElement> {

    private _slots: Set<string> = new Set('DMY' || '_');
    private _pattern: string =  'DD/MM/YYYY';
    private _accept: RegExp = null;
    private _back: boolean = false;
    
    @ChildRef('input')
    private _inputElement: HTMLInputElement = null;

    public constructor(node: HTMLInputElement) {
        super(node);
    }

    public onInit(): void {
        (this._node as any).name = this._inputElement.name;       
        this._accept = new RegExp(this._inputElement.dataset.accept || '\\d', 'g');              
    }

    @ClickListener('.a-date-box__clean')
    public onClean(): void {
        this.value = null;
        this.focus();
    }   

    @EventListener('input', 'input')
    public onInput(): void {
        this.format(false);
        this.addOrRemoveClass(this.value && !this._inputElement.disabled, 'a-date-box--not-empty');             
        this.dispatchCustomEvent('input');
    }

    @EventListener('focus', 'input')
    public onFocus(): void {
        this.format(true);
    }

    @EventListener('keydown', 'input')
    public onKeydown(e: KeyboardEvent): void {
        this._back = e.key === 'Backspace';
    }

    @EventListener('blur', 'input')
    public onBlur(): void {
        if (!this.value) {
            this.value = null;
        }
        if (this._inputElement.value === this._pattern) {
            this._inputElement.value = null;
        }
        this.dispatchCustomEvent('blur');
    }

    private format(focus: boolean): void {
        const previous: number[] = (j => Array.from(this._pattern, (c, i) => (this._slots.has(c) ? (j = i + 1) : j)))(0);
        const first: number = [...this._pattern].findIndex(c => this._slots.has(c));
        const [i, j] = [this._inputElement.selectionStart, this._inputElement.selectionEnd].map(i => {
            i = this.clean(this._inputElement.value.slice(0, i)).findIndex(c => this._slots.has(c));
            return i < 0
                ? previous[previous.length - 1]
                : this._back
                ? previous[i - 1] || first
                : i;
        });
        this._inputElement.value = this.clean(this._inputElement.value).join('');
        if (focus) {
            const parts: string[] = this._inputElement.value.split('/');
            if (parts[0] && parts[0].includes('D')) {
                this._inputElement.setSelectionRange(0, 2);
            } 
            else if (parts[1] && parts[1].includes('M')) {
                this._inputElement.setSelectionRange(3, 5);
            } 
            else if (parts[2] && parts[2].includes('Y')) {
                this._inputElement.setSelectionRange(6, 10);
            }
        } 
        else {
            this._inputElement.setSelectionRange(i, j);
        }
        this._back = false;
    }

    private clean(input: any): string[] {
        input = input.match(this._accept) || [];
        return Array.from(this._pattern, c => input[0] === c || this._slots.has(c) ? input.shift() || c : c);
    }

    public focus(): void {
    this._inputElement.focus();
    }

    public get value(): Date {
        const value: string = this._inputElement.value;
        if (value != this._pattern) {
            const parts: string[ ]= value.split('/').filter(item => !isNaN(parseInt(item)));
            if (parts.length === 3) {
                const day: number = parseInt(parts[0]);
                const month: number = parseInt(parts[1]) - 1;
                const year: number = parseInt(parts[2]);
                if (day < 1 || day > 31) {
                    return null;
                }
                else if (month < 0 || month > 11) {
                    return null;
                } 
                else if (year < 1900 || year > 2100) {
                    return null;
                } 
                else {
                    const date: Date = new Date(year, month, day);
                    if (isNaN(date.getTime())) {
                        return null;
                    } 
                    else {
                        return date;
                    }
                }
            } 
            else {
                return null;
            }
        }   
        else {
            return null;
        }
    }
    
    public set value(value: Date | string) {
        if (value) {
            if (typeof value === 'string') {
              value = new Date(value);
            }
            const day: string = value.getDate().toString().padStart(2, '0');
            const month: string = (value.getMonth() + 1).toString().padStart(2, '0');
            const year: string = value.getFullYear().toString();
            this._inputElement.value = `${day}/${month}/${year}`;
        } 
        else {
            this._inputElement.value = null;
        }
        this.onInput();
    }
}