import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { EventListener } from '../../../../../lib/web/components/event-listener';
import { ModalComponent } from '../../../../../lib/web/components/modal.component';
import { DictionaryObject } from '../../../../../lib/web/core/types';
import { ApiService } from '../../../services/api.service';
import { SessionService } from '../../../services/session.service';
import { ValidationService } from '../../../services/validation.service';
import { PasswordBoxComponent } from '../../atoms/password-box/password-box.component';

@Component({
    selector: '.m-change-password'
})
export class ChangePasswordComponent extends ModalComponent<HTMLInputElement> {

    @ChildRef('#txtPassword')
    private _txtPassword: PasswordBoxComponent = null;
    @ChildRef('#txtRepeatPassword')
    private _txtRepeatPassword: PasswordBoxComponent = null;
    
    public constructor(node: HTMLInputElement,
        private _apiService: ApiService,
        private _validationService: ValidationService,
        private _sessionService: SessionService) {
        super(node);
    }    

    public onInit(): void {
        super.onInit();
        this.registerFormFieldInput('.m-change-password__form', field => {
            if (this._txtPassword.value && this._txtRepeatPassword.value) {
                this.validate();
            }
            else {
                this.validateField(field);
            }
        });
    }

    public async open(): Promise<any> {
        const data: { currentPassword: string, password: string, repeatPassword: string } = { currentPassword: null, password: null, repeatPassword: null };
        this.setFormData(data);
        this._validationService.resetErrors('.m-change-password__form', data);
        this.disable('.m-change-password__save');
        this.focus('#txtCurrentPassword', 100);
        return super.open();
    }
    
    @EventListener( 'input', 'input')
    public onChange(): void {
        this.enable('.m-change-password__save');
    }

    @ClickListener('.m-change-password__cancel')
    public onCancel(): void {
        this.close();
    }

    @ClickListener('.m-change-password__save')
    public onSave(): void {
        if (this.validate()) {
            this.submit();
        }
    }

    private async submit(): Promise<void> {
        try {
            this.disable('.m-change-password__save');
            const data: any = this.getFormData('.m-change-password__form');
            const { user, authToken } = await this._apiService.post('/api/v1/user/change-password', { 
                body: data                 
            });
            if (user && authToken) {
                this._sessionService.set({
                    user,
                }, true);            
            }
            this.close();

        }
        catch (e: any) {
            if (e.statusCode == 400 && e.data?.message) {
                this._validationService.showErrors('.m-change-password__form', JSON.parse(e.data?.message));
            }
            else if (e.statusCode == 401) {
                this._validationService.showErrors('.m-change-password__form', { currentPassword: [USER_LOCALE.invalidCurrentPassword] });
            }
            else if (e.statusCode == 409) {
                this.showUnexpectedError({ message: e.data?.message });
            }
            else {
                this.showUnexpectedError(e);
            }
        }
        finally {
            this.enable('.m-change-password__save');
        }
    }

    public validate(): boolean {
        const data: any = this.getFormData('.m-change-password__form')
        const errors: DictionaryObject<string[]> = this._validationService.validateChangePassword(data, data);
        this._validationService.showErrors('.m-change-password__form', errors);
        return !this._validationService.hasErrors(errors);
    }

    public validateField(field: any): void {
        const errors: DictionaryObject<string[]> = this._validationService.validateChangePassword(field, this.getFormData('.m-change-password__form'));
        this._validationService.showErrors('.m-change-password__form', errors);
    }
}