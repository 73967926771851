import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { EventListener } from '../../../../../lib/web/components/event-listener';
import { Input } from '../../../../../lib/web/components/input';
import debounce from '../../../../../lib/web/core/debounce';
import { ApiService } from '../../../services/api.service';
import { SessionService } from '../../../services/session.service';
import { TextBoxComponent } from '../../atoms/text-box/text-box.component';
import { SearchFilterComponent } from '../../molecules/search-filter/search-filter.component';
import { SearchSuggestionsComponent } from '../../organisms/search-suggestions/search-suggestions.component';
import { GridComponent } from '../../organisms/grid/grid.component';
import { threadId } from 'worker_threads';

@Component({
    selector: '.p-search'
})
export class SearchComponent extends ComponentBase<HTMLElement> {

    @Input('totalPages')
    private _totalPages: number = null;

    private _lastSavedTerm: string = null;
    private _userTerms: { id: string, term: string, updatedOn: string }[] = null;

    @ChildRef()
    private _searchSuggestions: SearchSuggestionsComponent = null;
    @ChildRef('.p-search__filter-chips')
    private _filterChipsElement: HTMLDivElement = null;
    @ChildRef()
    private _grid: GridComponent = null;
    @ChildRef()
    private _txtTerm: TextBoxComponent = null;
    @ChildRef()
    public _searchFilter: SearchFilterComponent = null; 

    public constructor(node: HTMLElement, private _apiService: ApiService, private _sessionService: SessionService) {
        super(node);       
    }

    public onInit(mode: 'load' | 'redirect'): void {
        super.onInit(mode);
        this._grid.addCustomEventListener('load-page', (data: { page: number }) => this.loadPage(data));
        this._searchSuggestions.addCustomEventListener('remove', (data: { id: string }) => this.removeTerm(data.id));
        this._searchSuggestions.addCustomEventListener('selected', (data: { term: string }) => {
            this._txtTerm.value = data.term;
            this.onSearch();
        });
        this._txtTerm.addCustomEventListener('cleaned', (e: { value: string }) => {
            this.saveTerm(e.value);
            this.onSearch();
        });
        const onSearchListener: Function = debounce(() => this.onSearch());
        this._txtTerm.addCustomEventListener('keyup', () => onSearchListener());
        this.setDataVisibility();
        this.setFilter();
        this.focus('#txtTerm');
        this.getUserTerms();        
    }
   
    private async getUserTerms(): Promise<void> {
        this._userTerms = this._sessionService.isSigned ? await this._apiService.get(`api/v1/user/term/all`) || []: [];
        this.getSuggestions();
    }

    private async getSuggestions(): Promise<void> {
        const { term } = this.getFormData('.p-search__box');
        if (term || this._sessionService.isSigned) {            
            let values: { id: string, term: string, type: 'suggestion' | 'recent' }[] = this._userTerms.filter(t => !term || t.term.startsWith(term)).sort((a, b) => a.updatedOn <= b.updatedOn ? 1: -1).map(t => ({ id: t.id, term: t.term, type: 'recent' as any })).getRange(0, CONFIG.SEARCH_SUGGESTIONS_SIZE || 5);
            const suggestions: string[] = await this._apiService.get(`api/v1/content/search/suggestions`, { query: { term } });
            if (suggestions?.length) {
                values.push(...suggestions.map(s => ({ id: null, term: s, type: 'suggestion' as any })));
            }
            values = (term ? values.distinctBy(t => t.term).sort((a, b) => a.term > b.term ? 1: -1) : values).filter(s => s?.term?.toLowerCase() != term?.toLowerCase());
            this.showSuggestions(values.getRange(0, CONFIG.SEARCH_SUGGESTIONS_SIZE || 5));
        }
        else {
            this.showSuggestions();
        }
    }

    private showSuggestions(suggestions?: { term: string, type: 'suggestion' | 'recent' }[]): void {
        this._searchSuggestions.load(suggestions);
    }

    @ClickListener('#btnShowFilter')
    public async onShowFilter(): Promise<void> {
        const search: boolean = await this._searchFilter.open();
        if (search) {
            this.onSearch();
        }
    }

    private async onSearch(): Promise<void> {
        this.getSuggestions();
        this._grid.reset();
    }

    private async saveTerm(term: string): Promise<void> {
        if (term) {
            if (term.toLowerCase() != (this._lastSavedTerm || '').toLowerCase()) {                
                const id: string = await this._apiService.post('/api/v1/user/term', { query: { term } }) as any as string;
                this._lastSavedTerm = term;
                const [userTerm] = this._userTerms.filter(t => t.id == id);
                if (userTerm) {
                    userTerm.updatedOn = new Date().toJSON();
                }
                else {
                    this._userTerms = [
                        { id: id, term: this._lastSavedTerm, updatedOn: new Date().toJSON() },
                        ...this._userTerms
                    ];
                }
                this.getSuggestions();
            }
        }
        else {
            this._lastSavedTerm = null;
        }
    }   

    private async removeTerm(id: string): Promise<void> {
        this._userTerms = this._userTerms.filter(t => t.id != id);
        await this._apiService.delete(`/api/v1/user/term/${id}`);
        this.getSuggestions();
    }

    private async loadPage(data: { page: number }): Promise<void> {
        const { term } = this.getFormData('.p-search__box');
        const filter: { name: string, value: number | number[] }[] = this._searchFilter.getFilter();
        const filterParam: any = {};
        filter.forEach(f => {
            if (f.value) {
                filterParam[f.name] = Array.isArray(f.value) ? f.value.join(',') : f.value;
            }
        });
        let saveTermTimeout: NodeJS.Timer = null;
        clearTimeout(saveTermTimeout);
        saveTermTimeout = setTimeout(() => {            
            this.saveTerm(term);
        }, CONFIG.SAVE_SEARCH_TERM_INTERVAL);
        const { page } = data;
        const response: { grid: { title: string, items: any[], pageInfo: { currentPage: number, totalPages: number } } } = await this._apiService.get('/api/v1/content/search', { 
            query: {
                term,
                page,
                ...filterParam
            }
        });    
        const { grid } = response;
        this._totalPages = grid.pageInfo.totalPages;
        this.setDataVisibility();
        this.setFilterChips();
        this._grid.setPageResponse(grid.items, grid.pageInfo);
        this.setInnerHTML(grid.title, '.p-search__title');
        const queryString: any = {};
        if (term) {
            queryString.term = term;
        }
        filter.filter(f => f.value).forEach(f => queryString[f.name] = Array.isArray(f.value) ? f.value.join(','): f.value);
        const params: string = `${Object.keys(queryString).filter(key => !!queryString[key]).length? `?${Object.keys(queryString).filter(key => !!queryString[key]).map(key => `${key}=${queryString[key]}`).join('&')}` : ''}`;
        this.replaceState(`${location.pathname}${params}`);
    }

    private setDataVisibility(): void {
        this.addOrRemoveClass(this._totalPages > 0, 'u-hidden-important', '.p-search__no-data');
        this.addOrRemoveClass(this._totalPages == 0, 'u-hidden-important', '.p-search__grid');
        this.addOrRemoveClass(this._totalPages == 0, 'u-hidden-important', '.p-search__title');
        this.addOrRemoveClass(this._totalPages == 0, 'p-search__header--no-data', '.p-search__header');
    }

    private setFilter(): void {
        const queryString: URLSearchParams = new URLSearchParams(location.search);
        if (queryString.has('term')) {
            this._txtTerm.value = queryString.get('term');
        }    
        this.setFilterChips();
    }

    private setFilterChips(): void {
        this._filterChipsElement.innerHTML = '';
        this.removeClass('p-search__header--active-filter', '.p-search__header');
        const filter = this._searchFilter.getFilter();
        filter.filter(f => f.value && (!Array.isArray(f.value) || f.value.length)).forEach(f => {
            this._filterChipsElement.appendChild(this.generateFilterChip(f));
            this._searchFilter.sendDataFilterEvent(f);
        });
    }

    private generateFilterChip(filter: { title: string, name: string, value: number | number[] }): HTMLDivElement {
        const { title, name, value } = filter;
        const chipElement: HTMLDivElement = document.createElement('div');
        chipElement.dataset.name = name;
        chipElement.classList.add('p-search__filter-chip');
        
        const titleElement: HTMLDivElement = document.createElement('div');
        titleElement.innerHTML = `${title} (${Array.isArray(value) ? value.length: 1})`;
        titleElement.classList.add('p-search__filter-chip-title');
        chipElement.appendChild(titleElement);

        const clearElement: HTMLButtonElement = document.createElement('button');
        clearElement.classList.add('p-search__filter-chip-clear', 'u-button', 'u-button--icon');
        clearElement.addEventListener('click', () => {
            this._searchFilter.deselectGroup(name);
            this._filterChipsElement.removeChild(chipElement);
            this.onSearch();
        });
        chipElement.appendChild(clearElement);
        
        this.addClass('p-search__header--active-filter', '.p-search__header');
        return chipElement;
    }

    public dispose(): void {
        this.saveTerm(this._txtTerm.value);
        super.dispose();
    }
}