import { Injectable } from "../../../lib/web/reflection/injectable";

const VERTICAL_GAP: number = 5;

@Injectable({ type: 'singleton' })
export class TooltipService {

    private _counter: number = 1;

    public constructor() {
        window.addEventListener('resize', () => this.hideTooltips());
        window.addEventListener('click', e => {
            if ((e.target as HTMLElement).closest('[data-tooltip]') == null && (e.target as HTMLElement).closest('.u-tooltip-element') == null) {
                this.hideTooltips();
            }
        });
    }

    public init(): void {        
        this.hideTooltips();       
        this._counter = 1;
        this.fragment(document);
    }

    public fragment(e: HTMLElement | Document): void {
        const tooltipElements: HTMLElement[] = Array.from(e.querySelectorAll('[data-tooltip]'));
        tooltipElements.forEach(e => {
            e.addEventListener('mouseenter', () => this.showTooltip(e));
            e.addEventListener('mouseleave', () => this.hideTooltip(e));
        });
        if (document.body.classList.contains('touch')) {
            const clickElements: HTMLElement[] = Array.from(e.querySelectorAll('.u-tooltip-element'));
            clickElements.forEach(e => e.addEventListener('click', () => { 
                this.showTooltip(e, 5000);
            }));
        }
    }

    private showTooltip(e: HTMLElement, hideTimeout?: number): void {
        if (!e.dataset.tooltipId && e.dataset.tooltipEnabled !== 'false') {
            const title: string = e.dataset.tooltip;
            const placement: string = e.dataset.tooltipPlacement || 'top-center';
            const verticalPlacement: string = placement.split('-').length == 2 ? placement.split('-')[0]: 'top';
            const horizontalPlacement: string = placement.split('-').length == 2 ? placement.split('-')[1]: 'middle';
            const { top: targetTop, bottom: targetBottom, left: targetLeft, right: targetRight, width: targetWidth } = e.getBoundingClientRect();
            
            e.dataset.tooltipId = `tooltip-${this._counter}`;
            const tooltipElement: HTMLDivElement = document.createElement('div');
            tooltipElement.id = e.dataset.tooltipId;
            tooltipElement.innerHTML = title;
            tooltipElement.classList.add('u-tooltip');
            document.body.appendChild(tooltipElement);

            const { height, width } = tooltipElement.getBoundingClientRect();
            switch (verticalPlacement) {
                case 'bottom':
                    tooltipElement.style.top = `${targetBottom + VERTICAL_GAP + window.scrollY}px`;
                    break;
                default:
                    tooltipElement.style.top = `${targetTop - height - VERTICAL_GAP + window.scrollY}px`;
                    break;
            }
            switch (horizontalPlacement) {
                case 'center':
                    tooltipElement.style.left = `${Math.max(0, (targetLeft + (targetWidth /  2)) - (width / 2) + window.scrollX)}px`;
                    break;
                case 'right':
                    tooltipElement.style.left = `${Math.max(0, targetRight - width + window.scrollX)}px`;
                    break;
                default:
                    tooltipElement.style.left = `${Math.max(0, targetLeft + window.scrollX)}px`;
                    break;
            }               
        }
        if (hideTimeout) {
            setTimeout(() => {
                this.hideTooltip(e);
            }, hideTimeout);
        }
    }

    private hideTooltip(e: HTMLElement): void {
        if (e.dataset.tooltipId) {  
            const tooltipElement: HTMLDivElement = document.querySelector(`#${e.dataset.tooltipId}`);
            if (tooltipElement) {
                tooltipElement.parentNode.removeChild(tooltipElement);
            }
            e.dataset.tooltipId = '';
        }
    }

    public hideTooltips(): void {
        const tooltipDependencyElements: HTMLElement[] = Array.from(document.querySelectorAll('[data-tooltip]'));
        tooltipDependencyElements.forEach(e => {            
            this.hideTooltip(e);
        });
        const tooltipElements: HTMLElement[] = Array.from(document.querySelectorAll('.u-tooltip'));
        tooltipElements.forEach(e => {            
            e.parentNode.removeChild(e);
        });
    }
}