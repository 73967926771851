import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { ScrollService } from '../../../../../lib/web/services/scroll.service';
import { MediaPlayerService } from '../../../services/media-player.service';
import { PlayerComponent } from '../../molecules/player/player.component';

@Component({
    selector: '.p-about-old'
})
export class AboutOldComponent extends ComponentBase<HTMLElement> {

    @ChildRef()
    private _player: PlayerComponent = null;
    @ChildRef('.p-about-old__image img')
    private _headerImage: HTMLImageElement = null;
    
    public constructor(node: HTMLElement, private _mediaPlayerService: MediaPlayerService, private _scrollService: ScrollService) {
        super(node);     
        
        ['resize', 'scroll'].forEach(event => window.addEventListener(event, () => this.setHeaderImageAnchor()));

        this.addCustomWindowEventListener('pop-about-old-player', e => {
            if (location.pathname.endsWith('/play')) {                    
                this.loadPlayer(false);                
            }
            e.isHandled = true;
        });

        this._mediaPlayerService.on('player-closed', async () => {
            if (location.pathname.endsWith('/play') || location.pathname.endsWith('/play-trailer')) {
                history.back();
            }
        }, this);
    }

    public onInit(): void {
        super.onInit();
        setTimeout(() => {
            this.setHeaderImageAnchor();
        }, 500);
        if (location.pathname.endsWith('/play')) {
            this.loadPlayer(false);
        }
    }

    @ClickListener('.p-about-old__image-anchor')
    public onHeaderImageAnchor(): void {
        this._scrollService.scrollTo('.p-about-old__register');
    }

    @ClickListener('.p-about-old__view-video')
    public onViewVideo(): void {
        this.loadPlayer(true);
    }

    private setHeaderImageAnchor(): void {        
        if ((this._headerImage.height + 60) >= ((document.documentElement.clientHeight || window.innerHeight) + window.scrollY)) {
            this.removeClass('u-hidden', '.p-about-old__image-anchor');
        }
        else {
            this.addClass('u-hidden', '.p-about-old__image-anchor');
        }
    }

    private loadPlayer(pushState: boolean = true): void {
        this._mediaPlayerService.loadMedia({
            type: 'video',
            video: {
                id:CONFIG.ABOUT_VIDEO_ID,
                title: USER_LOCALE.aboutAppTitle
            },
            controls: {
                miniPlayer: 'disable'
            }         
        });        
        this.replaceState(`${location.pathname}${location.search}`, { popHandlerEvent: 'pop-about-old-player' });
        if (pushState) {
            this.pushState(`${location.pathname}/play`, { popHandlerEvent: 'pop-about-old-player' });
        }
    } 
    
    public dispose(): void {
        this._mediaPlayerService.off(this);
        super.dispose();
    }
}