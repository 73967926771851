import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { PopupComponent } from '../../../../../lib/web/components/popup.component';
import { ApiService } from '../../../services/api.service';
import { SessionService } from '../../../services/session.service';

@Component({
    selector: '.m-profile-menu'
})
export class ProfileMenuComponent extends PopupComponent<HTMLInputElement> {

    public constructor(node: HTMLInputElement, private _sessionService: SessionService, private _apiService: ApiService) {
        super(node);

        this.addCustomWindowEventListener('sign-out-request', () => this.signOut());
        this.addCustomWindowEventListener('change-language', e => this.changeLanguage(e));
    }   

    public onInit(): void {
        super.onInit();
        this.addClass(this._sessionService.isSigned ? 'm-profile-menu--signed': 'm-profile-menu--unsigned');
    }    

    @ClickListener('.m-profile-menu__profile')
    public onProfile(): void {
       this.close();
    }

    @ClickListener('.m-profile-menu__sign-out')
    public onSignOut(): void {
       this.signOut();
       this.close();
    }

    @ClickListener('.m-profile-menu__help')
    public onHelp(): void {
        this.close();
    }

    @ClickListener('.m-profile-menu__favorites')
    public onFavorites(): void {
        this.close();
    }    

    @ClickListener('.m-profile-menu__locale')
    public async onChangeLanguage(e: MouseEvent): Promise<void> {
        this.close();
        const locale: string = (e.target as HTMLButtonElement).dataset.locale;
        this.changeLanguage(locale);
    }

    private async changeLanguage(locale: string): Promise<void> {
        if (this._sessionService.isSigned) {
            try {
                await this._apiService.post(`/api/v1/user/setting/language/${locale}`);
                this._sessionService.setLanguage(locale);
                window.location.reload();
            }
            catch (e: any) {
                this.showUnexpectedError(e);
            }
        }
        else {
            this._sessionService.setLanguage(locale);
            window.location.reload();
        }
    }

    private async signOut(): Promise<void> {
        if (await this.showQuestion(USER_LOCALE.signOut, USER_LOCALE.signOutQuestion, 'ok', { result: 'cancel' }, { result: 'ok' }) == 'ok') {
            await this._apiService.delete('/api/v1/user/sign-in');
            this._sessionService.remove();
            this.redirect('/');
        }
    }
}