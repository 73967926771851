import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';

@Component({
    selector: '.a-playbackrate-menu'
})

export class PlaybackRateMenuComponent extends ComponentBase<HTMLInputElement> {

    private _playbackRates: { id: string, isSelected: boolean }[] = null;
    private _selectedPlaybackRate: string = null;

    @ChildRef('ul')
    private _listElement: HTMLUListElement = null;

    public constructor(node: HTMLInputElement) {
        super(node);
    }

    private show(): void {
        this.addClass('a-playbackrate-menu--show');
    }

    public hide(): void {
        this.removeClass('a-playbackrate-menu--show');
    }

    public toggle(): void {
        if (this._node.classList.contains('a-playbackrate-menu--show')) {
            this.hide();       
        } 
        else {
            this.show();
        }
    }

    public get playbackRates(): { id: string, isSelected: boolean }[] {
        return this._playbackRates;
    }

    public set playbackRates(value: { id: string, isSelected: boolean }[]) {
        this._playbackRates = value
            .filter((value, index, self) => self.findIndex(v => v.id === value.id) === index)
            .sort((a, b) => a.id < b.id ? 1: -1);
        // In case the selected playbackRate got deleted lets select the one that was left with same height
        if (this._playbackRates.length && !this._playbackRates.some(playbackRate => playbackRate.isSelected)) {
            const previousSelectedPlaybackRate = value.find(e => e.id === this._selectedPlaybackRate);
            this._playbackRates.find(playbackRate => playbackRate.id === previousSelectedPlaybackRate.id).isSelected = true;
            this._selectedPlaybackRate = this._playbackRates.find(playbackRate => playbackRate.isSelected).id;
        }

        this.setPlaybackRates();
    }

    public get selectedPlaybackRate(): string {
        return this._selectedPlaybackRate;
    }

    public set selectedPlaybackRate(value: string) {
        this._selectedPlaybackRate = value;
    }

    private setPlaybackRates(): void {
        this._listElement.innerHTML = null;
        this._playbackRates.forEach(playbackRate => {
            const playbackRateElement: HTMLLIElement = document.createElement('li');
            playbackRateElement.innerHTML = `${playbackRate.id}x`;
            playbackRateElement.dataset.id = playbackRate.id.toString();
            playbackRateElement.addEventListener('click', () => {
                this._selectedPlaybackRate = playbackRate.id;
                this.dispatchCustomEvent('rate-changed');
                this.hide();
                this.updatePlaybackRates();
            });
            this._listElement.appendChild(playbackRateElement);
        });
    }

    private updatePlaybackRates() {
        this._listElement.querySelectorAll('li').forEach(playbackRateElement => {
            playbackRateElement.classList.remove('a-playbackrate-menu__item--selected');
            if (playbackRateElement.dataset.id === this._selectedPlaybackRate) {
                playbackRateElement.classList.add('a-playbackrate-menu__item--selected');
            }
        });
        this._playbackRates.forEach(playbackRate => {
            playbackRate.isSelected = false;
            if (this.selectedPlaybackRate === playbackRate.id) {
                playbackRate.isSelected = true;
            }
        });
    }

    public unload(): void {
        this._playbackRates = null;
        this._selectedPlaybackRate = null;
    }

    public dispose(): void {
        this.hide();
        super.dispose();
    }
}