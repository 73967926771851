import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ChildrenRef } from '../../../../../lib/web/components/children-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';
import { Dom } from '../../../../../lib/web/core/dom';
import { ApiService } from '../../../services/api.service';
import { DataEventService } from '../../../services/data-event.service';
import { SessionService } from '../../../services/session.service';
import { IconComponent } from '../../atoms/icon/icon.component';
import { SeasonSelectorComponent } from '../../organisms/season-selector/season-selector.component';

@Component({
    selector: '.p-partner-landing'
})
export class PartnerLandingComponent extends ComponentBase<HTMLElement> {

    private _userData: { isFavorite?: boolean } = null;
    private _listsObserver: IntersectionObserver = null;

    @Input('id')
    private _id: number = null;

    @ChildRef('.p-partner-landing__synopsis-content')
    private _synposisContentElement: HTMLDivElement = null;
    @ChildRef('.p-partner-landing__synopsis-more button')
    private _synposisMoreElement: HTMLButtonElement = null;    
    @ChildRef('.a-icon--heart')
    private _favoriteIcon: IconComponent = null;
    @ChildRef()
    private _seasonSelector: SeasonSelectorComponent = null;
    @ChildrenRef(HTMLDivElement, '.p-partner-landing__grid')
    private _grids: HTMLDivElement[] = null;
    
    public constructor(node: HTMLElement, 
        private _apiService: ApiService,
        private _sessionService: SessionService,
        private _dataEventService: DataEventService) {
        super(node);   
        
        this.addWindowEventListener('resize', () => this.checkSynopsis());
    }

    public onInit(): void {
        super.onInit();
        if (this._sessionService.isSigned) {
            this.loadUserData();
        }
        if (this._seasonSelector) {
            this._seasonSelector.addCustomEventListener('selection-changed', (e: { id: number }) => this.setSeason(e.id, true));        
            this._listsObserver = new IntersectionObserver(() => this.checkSelectedSeason(), {});
            (this._grids || []).forEach(l => this._listsObserver.observe(l));
            if (location.pathname.split('/').length > 3) {
                const listSlug: string = location.pathname.split('/')[3];
                const selectedList: HTMLDivElement = this._node.querySelector(`.p-collection__list[data-slug="${listSlug}"]`);
                if (selectedList) {
                    setTimeout(() => {
                        this.setSeason(parseInt(selectedList.dataset.id));
                    }, 500);                
                }
            }
        }
        this.checkSynopsis();        
    }

    private checkSynopsis(): void {
        if (!this._synposisContentElement.classList.contains('p-partner-landing__synopsis-content--full')) {
            this.addOrRemoveClass(this._synposisContentElement.scrollHeight <= this._synposisContentElement.clientHeight, 'u-hidden', '.p-partner-landing__synopsis-more');
        }
    }

    private async loadUserData(): Promise<void> {
        const response: { isFavorite?: boolean } = await this._apiService.get(`/api/v1/user/data/${this._id}/partner-landing`);
        this._userData = response;
        this.setFavoriteOption();    
    }

    @ClickListener('.p-partner-landing__synopsis-more button')
    public onMoreSynopsis(): void {
        if (this._synposisContentElement.classList.contains('p-partner-landing__synopsis-content--full')) {
            this._synposisContentElement.classList.remove('p-partner-landing__synopsis-content--full');
            this._synposisMoreElement.textContent = USER_LOCALE.continueReading;
        }
        else {
            this._synposisContentElement.classList.add('p-partner-landing__synopsis-content--full');
            this._synposisMoreElement.textContent = USER_LOCALE.hide;
        }
    }

    @ClickListener('.p-partner-landing__toggle-favorite')
    public onToogleFavorite(): void {
        this.setFavorite();
    }

    private async setFavorite(): Promise<void> {
        const { isFavorite } = this._userData;
        if (isFavorite) {
            if (await this.showQuestion(USER_LOCALE.removeFromFavorite, USER_LOCALE.removeFromFavoriteQuestion.format('<br><br>'), 'ok', { result: 'cancel' }, { result: 'ok' }) == 'ok') {
                await this._apiService.delete(`/api/v1/user/favorite/${this._id}/partner-landing`);
                this._userData.isFavorite = false;
                this.setFavoriteOption();
            }
        }
        else {            
            await this._apiService.post(`/api/v1/user/favorite/${this._id}/partner-landing`);
            this._userData.isFavorite = true;
            this.setFavoriteOption();
            this._dataEventService.addFavorite('partner_landing', this._node.dataset.id, this._node.dataset.display, null, null);
        }
    }

    private setFavoriteOption(): void {
        const { isFavorite } = this._userData || {};
        this.removeClass('u-hidden-important', '.p-partner-landing__toggle-favorite');
        this.setInnerHTML(isFavorite ? USER_LOCALE.removeFromFavorite: USER_LOCALE.addToFavorite, '.p-partner-landing__toggle-favorite span:last-child');
        this._favoriteIcon.key = isFavorite ? 'heart-filled': 'heart';
    }

    private checkSelectedSeason(): void {
        const selectedGrid: HTMLDivElement = this._grids.filter(g => Dom.isScrolledIntoView(g)).last();
        if (selectedGrid) {
            this._seasonSelector.setSelected(selectedGrid.dataset.id);
        }
    }

    private setSeason(id: number, fromSelector: boolean = false): void {
        const selectedList: HTMLDivElement = this._grids.find(g => parseInt(g.dataset.id) == id);
        selectedList.scrollIntoView({ behavior: 'smooth' });
    }   
    
    public dispose(): void {
        if (this._listsObserver) {
            this._listsObserver.disconnect();
            this._listsObserver = null;
        }
        super.dispose();
    }
}