import { DictionaryObject } from "../../../lib/web/core/types";
import { Injectable } from "../../../lib/web/reflection/injectable";

type CompileFn = (data: any, param: any, callback: (key: string, data: any) => string) => string;

@Injectable({ type: 'singleton' })
export class EjsService {

    public render(key: string, data: any): string {
        const template: string = this.getTemplate(key);
        return this.compile(template, data);
    }

    private compile(template: string, data: any): string {
        const compileFn: CompileFn  = (window as any).ejs.compile(template, { client: true });
	    return compileFn(data, null, (key: string, data: any) => this.callback(key, data));
    }

    private escape(value: string): string {
        return value;
    }

    private callback(key: string, data: any): string {
        const template: string = this.getTemplate(key);
        return this.compile(template, data);
    }

    private getTemplate(key: string): string {
        const clientTemplates: DictionaryObject<string> = {
            ...(window as any).TEMPLATES,
            ...(window as any).clientTemplates,
        };
        if (clientTemplates) {
            key = key.replaceAll('../', '').replaceAll('.ejs', '');
            return clientTemplates[key];
        }
        else {
            return null;
        }
    }
}