import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { EventListener } from '../../../../../lib/web/components/event-listener';
import { ModalComponent } from '../../../../../lib/web/components/modal.component';

@Component({
    selector: '.a-country-selector'
})
export class CountrySelectorComponent extends ModalComponent<HTMLInputElement> {

    public constructor(node: HTMLInputElement) {
        super(node);
    }    

    public onInit(): void {
        super.onInit();
    }

    public async open(id: number): Promise<any> {
        const selectedCountry: { id: number, name: string } = id ? this.getCountry(id): null;
        (this.node.querySelector(`input[value='${id}']`) as HTMLInputElement).checked = true;
        this.setInnerHTML(USER_LOCALE.countrySelectorComment.format(selectedCountry.name), '.a-country-selector__comment');
        this.disable('.a-country-selector__ok');
        return await super.open();
    }
    
    @EventListener( 'change', '[name=countrySelector]')
    public onChange(): void {
        this.enable('.a-country-selector__ok');
    }

    @ClickListener('.a-country-selector__cancel')
    public onCancel(): void {
        this.close();
    }

    @ClickListener('.a-country-selector__ok')
    public onOk(): void {
        const selected: number = parseInt((this.node.querySelector(`input[name='countrySelector']:checked`) as HTMLInputElement || { value: null }).value);
        const country: { id: number, name: string } = this.getCountry(selected);
        this.dispatchCustomWindowEvent('country-selected', country);
        this.close(country);
    }

    private getCountry(id: number): { id: number, name: string } {
        const data: any = (this.node.querySelector(`.a-country-selector__item[data-id='${id}']`) as HTMLDivElement).dataset;
        return {
            ...data,
            id: parseInt(data.id)
        };
    }
}