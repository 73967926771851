import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ChildrenRef } from '../../../../../lib/web/components/children-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';
import { DateTime } from '../../../../../lib/web/core/date-time';
import { Str } from '../../../../../lib/web/core/str';
import { CapsuleSegment } from '../../../../../shared/server/model/capsule-segment';
import { ApiService } from '../../../services/api.service';
import { DataEventService } from '../../../services/data-event.service';
import { MediaOptions, MediaPlayerService, MediaType } from '../../../services/media-player.service';
import { SessionService } from '../../../services/session.service';
import { UserWatchingHistoryService } from '../../../services/user-watching-history.service';
import { IconComponent } from '../../atoms/icon/icon.component';
import { ItemSelectorDropdownComponent } from '../../atoms/item-selector-dropdown/item-selector-dropdown.component';
import { GridComponent } from '../../organisms/grid/grid.component';

@Component({
    selector: '.p-capsule'
})
export class CapsuleComponent extends ComponentBase<HTMLElement> {

    private _userData: { isFavorite?: boolean } = null;
    private _playerBack: boolean = false;
    private _segmentIsOpened: boolean = false;

    @Input('id')
    private _capsuleId: number = null;
    @Input('type')
    private _type: number = null;
    @Input('collectionId')
    private _collectionId: number = null;
    @Input('isComingSoon')
    private _isComingSoon: boolean = null;
    @Input('image')
    private _image: string = null;
    @Input('segments', { isJSON: true })
    private _segments: CapsuleSegment[] = null;
    @Input('freePlay')
    private _freePlay: boolean = null;
    @Input('licenseStart')
    private _licenseStart: Date = null;
    @Input('licenseEnd')
    private _licenseEnd: Date = null;

    @ChildRef('.p-capsule__synopsis-content')
    private _synposisContentElement: HTMLDivElement = null;
    @ChildRef('.p-capsule__synopsis-more button')
    private _synposisMoreElement: HTMLButtonElement = null;    
    @ChildRef('.p-capsule__select-segment')
    private _selectSegmentElement: HTMLButtonElement = null;
    @ChildRef('.p-capsule__segments-selector .a-item-selector-dropdown')
    private _segmentsSelector: ItemSelectorDropdownComponent = null;
    @ChildRef('.p-capsule__licenseStart')
    private _licenseStartElement: HTMLDivElement = null;
    @ChildRef('.p-capsule__licenseEnd')
    private _licenseEndElement: HTMLDivElement = null;
    @ChildRef('.o-grid')
    private _grid: GridComponent = null;
    @ChildrenRef(IconComponent, '.a-icon--heart')
    private _favoriteIcons: IconComponent[] = null;

    public constructor(node: HTMLElement, 
        private _apiService: ApiService,
        private _sessionService: SessionService,
        private _mediaPlayerService: MediaPlayerService,
        private _userWatchingHistoryService: UserWatchingHistoryService,
        private _dataEventService: DataEventService) {
        super(node);
        
        this.addWindowEventListener('resize', () => this.checkSynopsis());
        this.addCustomWindowEventListener('pop-capsule-player', e => {
            if (location.pathname.endsWith('/play') || location.pathname.endsWith('/play-trailer')) {
                this.loadPlayer(false, location.pathname.endsWith('/play-trailer') ? 'trailer': 'capsule');
            }
            else if (!this._mediaPlayerService.isMiniPlayerActive) {
                this._mediaPlayerService.closePlayer();
            }
            this.checkUserWatchingHistory();
            e.isHandled = true;
        });
        this._mediaPlayerService.on('player-closed', async () => {
            this.enable('.p-capsule__start-watch');
            this.enable('.p-capsule__trailer');
            this.enable('.p-capsule__unsigned-trailer');
            this.checkUserWatchingHistory();
            if (location.pathname.endsWith('/play') || location.pathname.endsWith('/play-trailer')) {
                history.back();
            }
        }, this);
        this._mediaPlayerService.on('mini-player-activated', async () => {
            this.disable('.p-capsule__start-watch');
            this.disable('.p-capsule__trailer');
            this.disable('.p-capsule__unsigned-trailer');
            if (this._playerBack) {
                history.back();
            }
            else if (location.pathname.endsWith('/play')) {
                this.replaceState(`${location.pathname.replace('/play', '')}${location.search}`, { popHandlerEvent: null });
            }
            else if (location.pathname.endsWith('/play-trailer')) {
                this.replaceState(`${location.pathname.replace('/play-trailer', '')}${location.search}`, { popHandlerEvent: null });
            }
        }, this);
        this._mediaPlayerService.on('mini-player-deactivated', async () => {
            this.enable('.p-capsule__start-watch');
            this.enable('.p-capsule__trailer');
            this.enable('.p-capsule__unsigned-trailer');
            if (this._mediaPlayerService.currentMedia) {
                this.setPlayState(true, this._mediaPlayerService.currentMedia.type);
            }
        }, this);        
    }

    public onInit(mode: 'load' | 'redirect'): void {
        super.onInit(mode);
        this._playerBack = mode == 'redirect';
        if (this._sessionService.isSigned) {
            this.loadUserData();
        }
        this.addClass(this._sessionService.isSigned || this._freePlay ? 'p-capsule__watch--signed': 'p-capsule__watch--unsigned', '.p-capsule__watch');
        this.checkSynopsis();        
        this.checkUserWatchingHistory();
        if (location.pathname.endsWith('/play') || location.pathname.endsWith('/play-trailer')) {
            this.play(false);
            this._playerBack = false;
        }
        else if (new URLSearchParams(location.search).get('play') === 'true') {
            this.replaceState(location.pathname);
            this.play(true);
            this._playerBack = false;
        }
        this.setLicenseDates();
        this.checkPlaying();  
    }

    private setLicenseDates(): void {
        if (this._isComingSoon && this._licenseStart) {
            const hasTime: boolean = DateTime.licenseDateHasTime(this._licenseStart);
            this._licenseStartElement.innerHTML = (hasTime ? USER_LOCALE.availableFromWithTime: USER_LOCALE.availableFrom).format(DateTime.format(this._licenseStart, 'date'), DateTime.format(this._licenseStart, 'short-time'));
            this._licenseStartElement.classList.remove('u-hidden');
        }
        else if (this._licenseEnd) {
            const hasTime: boolean = DateTime.licenseDateHasTime(this._licenseEnd);
            this._licenseEndElement.innerHTML = (hasTime ? USER_LOCALE.availableUntilWithTime: USER_LOCALE.availableUntil).format(DateTime.format(this._licenseEnd, 'date'), DateTime.format(this._licenseEnd, 'short-time'));
            this._licenseEndElement.classList.remove('u-hidden');
        }
    }

    private checkSynopsis(): void {
        if (!this._synposisContentElement.classList.contains('p-capsule__synopsis-content--full')) {
            this.addOrRemoveClass(this._synposisContentElement.scrollHeight <= this._synposisContentElement.clientHeight, 'u-hidden', '.p-capsule__synopsis-more');
        }
    }

    private async loadUserData(): Promise<void> {
        const response: { isFavorite?: boolean } = await this._apiService.get(`/api/v1/user/data/${this._capsuleId}/capsule`);
        this._userData = response;
        this.setFavoriteOption();    
    }

    @ClickListener('.p-capsule__start-watch')
    public startWatch(): void {
        this.loadPlayer();        
    }

    @ClickListener('.p-capsule__trailer')
    public trailer(): void {
        this.loadPlayer(true, 'trailer');
    }

    @ClickListener('.p-capsule__unsigned-trailer')
    public unsignedTrailer(): void {
        this.loadPlayer(true, 'trailer');
    }    
    
    @ClickListener('.p-capsule__synopsis-more button')
    public onMoreSynopsis(): void {
        if (this._synposisContentElement.classList.contains('p-capsule__synopsis-content--full')) {
            this._synposisContentElement.classList.remove('p-capsule__synopsis-content--full');
            this._synposisMoreElement.textContent = USER_LOCALE.continueReading;
        }
        else {
            this._synposisContentElement.classList.add('p-capsule__synopsis-content--full');
            this._synposisMoreElement.textContent = USER_LOCALE.hide;
        }
    }

    @ClickListener('.p-capsule__toggle-favorite')
    public onToogleFavorite(): void {
        this.setFavorite();
    }

    @ClickListener('.p-capsule__remove-continue-watching')
    public onRemoveContinueWatching(): void {
        this.removeContinueWatching();
    }

    @ClickListener('.p-capsule__select-segment')
    public onSelectSegment(e: MouseEvent): void {
        this.toggleSegments(e);
    }

    private async toggleSegments(e: MouseEvent): Promise<void> {
        const arrowElement: HTMLElement = this._selectSegmentElement.querySelector('.u-arrow');
        arrowElement.classList.remove('u-arrow--down');
        arrowElement.classList.add('u-arrow--up');
        this._segmentIsOpened = false;
        const selected: string = await this._segmentsSelector.toggle({ selected: null, element: this._selectSegmentElement, mode: 'centered' }, e);        
        arrowElement.classList.remove('u-arrow--up');
        arrowElement.classList.add('u-arrow--down');
        const { brightcoveId, display } = this._node.dataset;
        if (!this._segmentIsOpened) {
            this._dataEventService.openFragment(brightcoveId, display, 'landing');
        }
        this._segmentIsOpened = true;
        if (selected) {
            const segment = (this._segments || []).find(s => s.id == parseInt(selected));
            if (segment && segment.position) {
                this.loadPlayer(true, 'capsule', segment);
                this._dataEventService.selectFragment(brightcoveId, display, 'landing', segment.title);
            }
        }
    }

    private async setFavorite(): Promise<void> {
        const { isFavorite } = this._userData;
        if (isFavorite) {
            if (await this.showQuestion(USER_LOCALE.removeFromFavorite, USER_LOCALE.removeFromFavoriteQuestion.format('<br><br>'), 'ok', { result: 'cancel' }, { result: 'ok' }) == 'ok') {
                await this._apiService.delete(`/api/v1/user/favorite/${this._capsuleId}/capsule`);
                this._userData.isFavorite = false;
                this.setFavoriteOption();
            }
        }
        else {            
            await this._apiService.post(`/api/v1/user/favorite/${this._capsuleId}/capsule`);
            this._userData.isFavorite = true;
            this.setFavoriteOption();
            this._dataEventService.addFavorite('video', this._node.dataset.brightcoveId, this._node.dataset.display, this._node.dataset.disciplines, this._node.dataset.typology);
        }
    }

    private setFavoriteOption(): void {
        const { isFavorite } = this._userData || {};
        this.removeClass('u-hidden-important', '.p-capsule__toggle-favorite');
        this.setInnerHTML(isFavorite ? USER_LOCALE.removeFromFavorite: USER_LOCALE.addToFavorite, '.p-capsule__toggle-favorite span:last-child');
        this._favoriteIcons.forEach(i => i.key = isFavorite ? 'heart-filled': 'heart');
    }

    private async removeContinueWatching(): Promise<void> {
        await this._apiService.post(`/api/v1/user/watching/archive/${this._collectionId ? 'collection' : 'capsule'}/${this._collectionId ? this._collectionId: this._capsuleId}`);
        this._userWatchingHistoryService.archive(this._collectionId ? this._collectionId: this._capsuleId, this._collectionId ? 'collection': 'capsule');
        this.addClass('u-hidden-important', '.p-capsule__remove-continue-watching');
        this.checkUserWatchingHistory();
    }

    private checkUserWatchingHistory(): void {
        if (this._isComingSoon) {
            this.setInnerHTML(USER_LOCALE.comingSoonTag, '.p-capsule__start-watch span');
            this.disable('.p-capsule__start-watch');
        }
        else if (this._userWatchingHistoryService.isWatching(this._capsuleId, 'capsule')) {
            this.setInnerHTML(this._type == CONFIG.CAPSULE_VIDEO_TYPE ? USER_LOCALE.continueWatching: USER_LOCALE.continueListening, '.p-capsule__start-watch span');
            if (!this._userWatchingHistoryService.getItem(this._collectionId ? this._collectionId: this._capsuleId, this._collectionId ? 'collection': 'capsule').isArchived) {
                this.removeClass('u-hidden-important', '.p-capsule__remove-continue-watching');
            }
        }
        else {
            this.setInnerHTML(this._type == CONFIG.CAPSULE_VIDEO_TYPE ? USER_LOCALE.startWatch: USER_LOCALE.startListen, '.p-capsule__start-watch span');
        }
        if (this._grid) {
            (this._grid.itemElements || []).forEach(item => {
                const id: number = parseInt(item.dataset.id);
                const watchingHistory: any = this._userWatchingHistoryService.getItem(id, 'capsule');
                if (watchingHistory) {
                    this._grid.setProgress(id, watchingHistory);
                }
                if (id == this._capsuleId) {
                    item.classList.add('o-grid__item--disabled');
                }
            });
        }
    }

    private play(pushState: boolean = true): void {        
        if (!this.checkPlaying()) {            
            this.loadPlayer(pushState, location.pathname.endsWith('/play-trailer') ? 'trailer': 'capsule');
        }
    }  
    
    private checkPlaying(): boolean {
        const options: MediaOptions = this._mediaPlayerService.currentMedia || {} as any;
        if (options.capsuleId == this._capsuleId) {
            this.disable('.p-capsule__start-watch');
            this.disable('.p-capsule__trailer');
            this.disable('.p-capsule__unsigned-trailer');        
            this.setPlayState(!location.pathname.endsWith('/play') && !location.pathname.endsWith('/play-trailer'), this._mediaPlayerService.currentMedia.type);
            return true;
        }
        return false;
    }  

    private async loadPlayer(pushState: boolean = true, type: MediaType = 'capsule', segment?: any): Promise<void> {                
        this._mediaPlayerService.loadMedia({
            capsuleId: this._capsuleId,
            collectionId: this._collectionId,
            type,
            image: this._image,
            currentTime: segment?.position,
            owner: {
                pathname: location.pathname,
                target: Str.getRelativeUrl(location.href)
            }
        });
        this.setPlayState(pushState, type);
    }

    private setPlayState(pushState: boolean = true, type: MediaType): void {
        if (!this._mediaPlayerService.isMiniPlayerActive) {
            this.replaceState(`${location.pathname}${location.search}`, { popHandlerEvent: 'pop-capsule-player' });
            if (pushState) {
                this.pushState(type == 'trailer' ? `${location.pathname}/play-trailer`: `${location.pathname}/play`, { popHandlerEvent: 'pop-capsule-player' });
            }
        }
    }   

    public dispose(): void {
        this._mediaPlayerService.off(this);
        super.dispose();
    }
}